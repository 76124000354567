import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {isEmpty} from 'lodash';
import {flushAppDetails, loginFromECommerceSite} from '../redux/actions/Auth';
import {NotificationManager, NotificationContainer} from 'react-notifications';
class ExternalLinkServer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    const {clientConfigration} = this.props;
    const {serverConfig} = clientConfigration;
    const {institute_lms_logout_url} = !!serverConfig && serverConfig;
    //reset all app redux data
    this.props.flushAppDetails();
    // extract login details form url and save same
    const url = window.location.href;
    const pathname = new URL(url).pathname;
    var splitURL = pathname.toString().split('/');
    const type = splitURL[2];
    if (type === 'login') {
      const emailId = splitURL[3];
      const userId = parseInt(splitURL[4]);
      const userTypeId = parseInt(splitURL[5]);
      const jwt = splitURL[6];
      const timestamp = splitURL[7];
      const refTimeStamp = new Date().getTime() - 10000;
      if (!isEmpty(emailId) && !!userId && !!userTypeId && !isEmpty(jwt) && timestamp >= refTimeStamp) {
        this.props.loginFromECommerceSite({emailId, userId, userTypeId, jwt});
      } else {
        NotificationManager.error('Your session is expired please login again.');
        const redirectURL = institute_lms_logout_url;
        if (redirectURL) window.location.href = redirectURL;
      }
    }
  }

  render() {
    return (
      <div>
        <NotificationContainer />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({flushAppDetails, loginFromECommerceSite}, dispatch);
};

const mapStateToProps = ({auth}) => {
  const {authUser, signinStatus, userRole, employeeId, clientConfigration} = auth;
  return {
    authUser,
    signinStatus,
    userRole,
    employeeId,
    clientConfigration,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLinkServer);

import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AmerikoolerHeader from '../components/AmerikoolerHeader/AmerikoolerHeader';
// import Sidebar from 'containers/SideNav/index';
import {COLLAPSED_DRAWER, FIXED_DRAWER} from '../constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import {showIdleModal} from '../redux/actions/Common';
import IdleTimer from 'react-idle-timer';
import {withStyles} from '@material-ui/core/styles';
import IdleTimeOutModal from '../containers/IdleModal/IdleModal';
import config from '../config/config';
import Learner from './routes/Learner';
import Instructor from './routes/Instructor';
import Admin from './routes/Admin';
import HeaderAlert from '../components/HeaderAlert/HeaderAlert';
import { getDefaultDomainDetails} from '../util/Extra';


const styles = theme => ({
  root: {
    height: '100%',
    flex: '1 1',
  },
});
class App extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isTimedOut: false,
    };
    this.idleTimer = null;
  }

  onIdle = () => {
    const isTimedOut = this.state.isTimedOut;
    if (!isTimedOut) {
      this.props.showIdleModal(true);
      this.idleTimer.reset();
      this.setState({isTimedOut: true});
    }
  };

  closeIdleModal = () => {
    this.props.showIdleModal(false);
    this.setState({isTimedOut: false});
  };

  render() {
    const {match, drawerType, userRole, idleModal, schoologyAppCourseListFlag} = this.props;
    console.log('schoologyAppCourseListFlag' , schoologyAppCourseListFlag)
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }
    const clientName = getDefaultDomainDetails().themeColor;
    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* {userRole === 'instructor' && <Sidebar {...this.props} />} */}
        <div className="app-main-container">
          <HeaderAlert />
          {!schoologyAppCourseListFlag && <AmerikoolerHeader />}       
          <main className={`${(clientName==="tdn-theme" && userRole === 'student') ? 'app-main-content-wrapper-two' : 'app-main-content-wrapper'} xyz`}>
            <div className={`app-main-content ${userRole === 'instructor' && 'app-wrapper'}`}>
              {console.log(userRole)}
              <IdleTimer
                ref={ref => {
                  this.idleTimer = ref;
                }}
                element={document}
                onIdle={this.onIdle}
                debounce={250}
                timeout={1000 * 60 * config.sessionTimeoutMinuteValue}
              />
              <Switch>
                <Route
                  path={`${match.url}/${userRole}`}
                  component={
                    userRole === 'learner'
                      ? Learner
                      : userRole === 'instructor'
                      ? Instructor
                      : userRole === 'student'
                      ? Learner
                      : userRole === 'admin'
                      ? Admin
                      : asyncComponent(() => import('components/Error404/Error404'))
                  }
                />
                <Route component={asyncComponent(() => import('components/Error404/Error404'))} />
              </Switch>
            </div>
            <IdleTimeOutModal open={idleModal} onClose={this.closeIdleModal} />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({settings, auth, commonData}) => {
  const {drawerType} = settings;
  const {userRole, schoologyAppCourseListFlag} = auth;
  const {idleModal} = commonData;
  return {
    drawerType,
    userRole,
    idleModal,
    schoologyAppCourseListFlag,
  };
};
export default withRouter(connect(mapStateToProps, {showIdleModal})(withStyles(styles)(App)));

const bool = (value, defaultValue) => (value === undefined ? defaultValue : value);

const development = {
  serverURL: process.env.REACT_APP_SERVER_URL,
  cryptoKey: process.env.REACT_APP_CRYPTO_KEY,
  GENERATE_SOURCEMAP: bool(process.env.GENERATE_SOURCEMAP, false),
  sessionTimeoutMinuteValue: 60,
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,

  amerikooler: {
    api_key: process.env.REACT_APP_API_KEY_FOR_AMERIKOOLER,
    server_url: process.env.REACT_APP_SERVER_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    scorm_launcher_url_link: process.env.REACT_APP_SCORM_LAUNCHER_URL_LINK,
    institute_key: 2,
    themeColor: process.env.REACT_APP_THEME_COLOR_FOR_AMERIKOOLER,
    instituteName: process.env.REACT_APP_THEME_INTITUTE_NAME_FOR_AMERIKOOLER,
    instituteLogo: process.env.REACT_APP_THEME_INTITUTE_LOGO_FOR_AMERIKOOLER
  },
  tdn: {
    api_key: process.env.REACT_APP_API_KEY_FOR_AMERIKOOLER,
    server_url: process.env.REACT_APP_SERVER_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    scorm_launcher_url_link: process.env.REACT_APP_SCORM_LAUNCHER_URL_LINK,
    institute_key: 3,
    themeColor: process.env.REACT_APP_THEME_COLOR_FOR_TDN,
    instituteName: process.env.REACT_APP_THEME_INTITUTE_NAME_FOR_TDN,
    instituteLogo: process.env.REACT_APP_THEME_INTITUTE_LOGO_FOR_TDN
  },
  paktolus: {
    api_key: process.env.REACT_APP_API_KEY_FOR_AMERIKOOLER,
    server_url: process.env.REACT_APP_SERVER_URL,
    socket_url: process.env.REACT_APP_SOCKET_URL,
    scorm_launcher_url_link: process.env.REACT_APP_SCORM_LAUNCHER_URL_LINK,
    institute_key: 1,
    themeColor: process.env.REACT_APP_THEME_COLOR_FOR_PAKTOLUS,
    instituteName: process.env.REACT_APP_THEME_INTITUTE_NAME_FOR_PAKTOLUS,
    instituteLogo: process.env.REACT_APP_THEME_INTITUTE_LOGO_FOR_PAKTOLUS
  },
};

export default development;

import React from 'react';
import Alert from '@material-ui/lab/Alert';
import SubmitLoader from '../../components/SubmitLoader/SubmitLoader';
import {connect} from 'react-redux';
import {resendEmailVerificationLink} from '../../redux/actions/Auth';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {isEmpty} from 'lodash';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const HeaderAlert = ({resendEmailVerificationLoader, resendEmailVerificationLink, authUser, userId, amerikoolerProfileData}) => {
  const {hash, hasVerifiedEmail} = !!amerikoolerProfileData && amerikoolerProfileData;
  const classes = useStyles();
  return (
    <div className='HeaderAlertWrapper'>
      {!isEmpty(amerikoolerProfileData) && !hasVerifiedEmail && (
        <Alert
          variant="filled"
          severity="error"
          classes={{
            root: classes.root, // class name, e.g. `classes-nesting-root-x`
            icon: classes.icon, // class name, e.g. `classes-nesting-root-x`
            message: classes.message, // class name, e.g. `classes-nesting-root-x`
          }}
        >
          <span className='message'>Confirm Your Email Address! Please check your email to verify your account.</span>
          <button
            type="button"
            className="resend-default-btn"
            disabled={resendEmailVerificationLoader}
            onClick={() => {
              resendEmailVerificationLink({emai: authUser, user_id: userId, hash: hash});
            }}
          >
            Resend Verification Link {resendEmailVerificationLoader && <SubmitLoader />}
          </button>
        </Alert>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resendEmailVerificationLink,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, profile}) => {
  const {resendEmailVerificationLoader, authUser, userId} = auth;
  const {amerikoolerProfileData} = profile;

  return {
    resendEmailVerificationLoader,
    authUser,
    userId,
    amerikoolerProfileData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAlert);

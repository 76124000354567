import {omit} from 'lodash';
import {encryptData, decryptData} from '../../util/Crypto';
import {isEmpty} from 'lodash';

const saveState = state => {
  try {
    const serializedEncryptedState = encryptData(JSON.stringify(omit(state, ['router', 'course'])));
    sessionStorage.setItem('state', serializedEncryptedState);
  } catch (e) {}
};

export const loadState = () => {
  try {
    const serializedEncryptedState = sessionStorage.getItem('state');
    const decryptedSerializedSatate = JSON.parse(decryptData(serializedEncryptedState));

    const mergedStates = {
      ...decryptedSerializedSatate,
    };

    if (isEmpty(decryptedSerializedSatate)) return undefined;
    return mergedStates;
  } catch (e) {
    return undefined;
  }
};

export const persistedState = loadState();

export const persistanceStateConfig = store => {
  store.subscribe(() => saveState(store.getState()));
};

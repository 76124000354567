import React from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import asyncComponent from '../../../util/asyncComponent';
class Instructor extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {match} = this.props;
    return (
      <React.Fragment>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
          <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./routes/Dashboard/Dashboard'))} />
          <Route path={`${match.url}/course`} component={asyncComponent(() => import('./routes/Course/Course'))} />
          <Route path={`${match.url}/courselist`} component={asyncComponent(() => import('./routes/CourseList/CourseList'))} />
          <Route path={`${match.url}/addcoursesuccess`} component={asyncComponent(() => import('./routes/AddCourseSuccessPopup/AddCourseSuccessPopup'))} />
          <Route path={`${match.url}/change-password`} component={asyncComponent(() => import('./routes/ChangePassword/ChangePassword'))} />
          <Route component={asyncComponent(() => import('components/Error404/Error404'))} />
        </Switch>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({settings, auth}) => {
  const {drawerType, navigationStyle} = settings;
  const {userRole} = auth;
  return {
    drawerType,
    navigationStyle,
    userRole,
  };
};
export default withRouter(connect(mapStateToProps, null)(Instructor));

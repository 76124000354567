import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Profile from './Profile';
import AmerikoolerCourse from './AmerikoolerCourse';
import Dashboard from './Dashboard';
import Admin from './Admin';
import Error from './Error';

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    commonData: Common,
    profile: Profile,
    amerikoolerCourse: AmerikoolerCourse,
    dashboard: Dashboard,
    admin: Admin,
    error: Error,

  });

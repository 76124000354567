import * as dashboardType from '../../constants/ActionTypes';


export const fetchDashboardData = data => {
  return {
    type: dashboardType.FETCH_DASHBOARD_DATA,
    payload: data,
  };
};

export const saveDashboardData = data => {
  return {
    type: dashboardType.SAVE_DASHBOARD_DATA,
    payload: data,
  };
};

export const dashboardLoader = val => {
  return {
  type: dashboardType.DASHBOARD_LOADER,
  payload: val,
  };
};

export const fetchDashboardCourseSData = data => {
  return {
    type: dashboardType.FETCH_DASHBOARD_COURSES_DATA,
    payload: data,
  };
};
export const saveDashboardCourseSData = data => {
  return {
    type: dashboardType.SAVE_DASHBOARD_COURSES_DATA,
    payload: data,
  };
};

export const setCurrentSectionData = data => {
  return {
    type: dashboardType.SET_CURRENT_SELECTION_Data,
    payload: data,
  };
};

export const fetchSchoologyAddCourseDetails = data => {
  return {
    type: dashboardType.FETCH_SCHOOLOGY_ADD_COURSE_DETAILS,
    payload: data,
  };
};

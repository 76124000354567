import * as CourseType from '../../constants/ActionTypes';

//Fetch profile data from here
export const fetchProfileData = () => {
  return {
    type: CourseType.FETCH_PROFILE_DATA,
  };
};

export const fetchProfileDataSuccess = profileData => {
  return {
    type: CourseType.FETCH_PROFILE_DATA_SUCCESS,
    payload: profileData,
  };
};

export const fetchProfileDataFail = errorMsg => {
  return {
    type: CourseType.FETCH_PROFILE_DATA_FAIL,
    payload: errorMsg,
  };
};

// Loader
export const profileLoaderShow = () => ({
  type: CourseType.PROFILE_SHOW_LOADER,
});

export const profileLoaderHide = () => ({
  type: CourseType.PROFILE_HIDE_LOADER,
});

// fetch amerikooler user profile data
export const fetchAmerikoolerProfileData = () => ({
  type: CourseType.FETCH_AK_PROFILE_DATA,
});

// save amerikooler user profile data
export const saveAmerikoolerProfileData = data => {
  return {
    type: CourseType.SAVE_AK_PROFILE_DATA,
    payload: data,
  };
};

// update amerikooler user profile data
export const updateAmerikoolerProfileData = data => {
  return {
    type: CourseType.UPDATE_AK_PROFILE_DATA,
    payload: data,
  };
};

// export const setHasEmailVerifiedFlag = data => {
//   return {
//     type: CourseType.SET_HAS_EMAIL_VERIFIED_FLAG,
//     payload: data,
//   };
// }
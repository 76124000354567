import * as dashboardType from '../../constants/ActionTypes';

const INIT_STATE = {
  dashboardTableData: null,
  dashboardLoader: false,
  dashboardCoursesData: null,
  dashboardCourseSelectionData: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case dashboardType.SAVE_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardTableData: action.payload.dashboardTableData,
      };
    }
    case dashboardType.DASHBOARD_LOADER: {
      return {
        ...state,
        dashboardLoader: action.payload,
      };
    }

    case dashboardType.SAVE_DASHBOARD_COURSES_DATA: {
      return {
        ...state,
        dashboardCoursesData: action.payload.dashboardCoursesData,
      };
    }
    case dashboardType.SET_CURRENT_SELECTION_Data: {
      return {
        ...state,
        dashboardCourseSelectionData: action.payload,
      };
    }


    default:
      return state;
  }
};

import React from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {NotificationManager, NotificationContainer} from 'react-notifications';
import {bindActionCreators} from 'redux';
import {isEmpty} from 'lodash';
import { userSignIn, flushAppDetails, fetchTdnSsoLoginDetails } from '../redux/actions/Auth';
import getQueryParams from '../util/QueryString';
import { extractClientNameFromURL} from '../util/Extra';

class Oauth extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  componentDidMount() {
    const {fetchTdnSsoLoginDetails,clientConfigration,authUser} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;
    const code = getQueryParams(window.location.search, 'code');
    if (!isEmpty(code) && !isEmpty(serverConfig) && authUser === null) {
      fetchTdnSsoLoginDetails({ code: code });
    } 
  }

  static getDerivedStateFromProps(nextProps) {
    const {signinStatus, history, authUser, userRole, clientConfigration} = nextProps;
    extractClientNameFromURL();
    const {serverConfig} = !!clientConfigration && clientConfigration;
    
    isEmpty(serverConfig) && history.push('/configration'); 
    
    // if already user logged in (browser back navigation). and user login to app
    
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app/${userRole}`);
    }
    //else{
    //   // window.location.href = 'https://clever.com/in/stimulating-desk-5966';
    // }
    // const {institute_lms_logout_url} = !!serverConfig && serverConfig;
    // eslint-disable-next-line
  //   var redirectURL = null;
  //   if (!!institute_lms_logout_url) {
  //     redirectURL = institute_lms_logout_url;
  //   }

  //   if (!!redirectURL) window.location.href = redirectURL;
  //   else window.location.href = 'https://clever.com/in/stimulating-desk-5966';

  return null;
  }
  render() {
    const {alertMessage, showMessage} = this.props;

    return (
      <div className={`app-container pre-signup-wrapper`}>
        <div className="app-main-container">
          <main className="app-main-content-wrapper email-verification">
            <div className="app-main-content">
              <div className="loader-view">
                <CircularProgress />
              </div>
              {showMessage && NotificationManager.error(alertMessage)}
              <NotificationContainer />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignIn,
      flushAppDetails,
      fetchTdnSsoLoginDetails,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth}) => {
  const {loader, authUser, signinStatus, userRole, clientConfigration} = auth;
  return {
    loader,
    authUser,
    signinStatus,
    userRole,
    clientConfigration,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Oauth);

import * as CourseType from '../../constants/ActionTypes';
import {all, fork, put, takeLatest, select} from 'redux-saga/effects';
import axios from 'util/Api';
import {profileLoaderHide, profileLoaderShow, fetchProfileDataSuccess, saveAmerikoolerProfileData} from '../actions/Profile';
import {errorHandler} from '../actions/Error';
import {cleanObject} from 'util/Extra';
import {ToastManager} from '../../components/ToastManager/ToastManager';
// profile details fetch worker
export function* profileContent() {
  try {
    yield put(profileLoaderShow());
    const profileResponse = yield axios.get(`/my-profile`);
    if (profileResponse.data) {
      yield put(fetchProfileDataSuccess(profileResponse.data));
    }
  } catch (error) {
    if (!error.response) {
      // yield put(errorHandler(error));
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  yield put(profileLoaderHide());
}

// course details fetch watcher
export function* fetchProfileDataWatcher() {
  yield takeLatest(CourseType.FETCH_PROFILE_DATA, profileContent);
}

// amerikooler profile details fetch worker
export function* fetchAmerikoolerProfileDataWorker() {
  try {
    yield put(profileLoaderShow());
    const profileResponse = yield axios.get(`/my-profile`);
    if (profileResponse.data) {
      yield put(saveAmerikoolerProfileData(profileResponse.data));
    }
  } catch (error) {
    if (!error.response) {
      // yield put(errorHandler(error));
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  yield put(profileLoaderHide());
}

// amerikooler profile details fetch watcher
export function* fetchAmerikoolerProfileDataWatcher() {
  yield takeLatest(CourseType.FETCH_AK_PROFILE_DATA, fetchAmerikoolerProfileDataWorker);
}

// amerikooler profile details update worker
export function* updateAmerikoolerProfileDataWorker({payload: {profileData}}) {
  try {
    const reduxState = yield select();
    const {profile} = reduxState;
    const {amerikoolerProfileData} = profile;
    yield put(profileLoaderShow());
    const newProfileObj = {...profileData};
    // removed empty field from profile obj
    cleanObject(newProfileObj);
    const profileResponse = yield axios.post(`/my-profile`, newProfileObj);
    let merged = {...amerikoolerProfileData, ...profileData};
    if (profileResponse.data) {
      yield put(saveAmerikoolerProfileData({...merged}));
      ToastManager.success('Profile updated successfully.', null, 6000);
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
  yield put(profileLoaderHide());
}

// amerikooler profile details update watcher
export function* updateAmerikoolerProfileDataWatcher() {
  yield takeLatest(CourseType.UPDATE_AK_PROFILE_DATA, updateAmerikoolerProfileDataWorker);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchProfileDataWatcher), fork(fetchAmerikoolerProfileDataWatcher), fork(updateAmerikoolerProfileDataWatcher)]);
}

import * as AdminType from '../../constants/ActionTypes';

const INIT_STATE = {
  dstrictAdminSchoolsData: null,
  dstrictAdminSchoolsSectionsData: null,
  dstrictAdminSchoolsSectionsUsersData: null,
  dstrictAdminSchoolsSectionsUsersReportData: null,

  adminLoader: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case AdminType.SAVE_DISTRICT_ADMIN_SCHOOLS_DATA: {
      return {
        ...state,
        dstrictAdminSchoolsData: action.payload,
      };
    }
    case AdminType.ADMIN_LOADER: {
      return {
        ...state,
        adminLoader: action.payload,
      };
    }

    case AdminType.SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_DATA: {
      return {
        ...state,
        dstrictAdminSchoolsSectionsData: action.payload,
   
      };
    }
    case AdminType.SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_DATA: {
      return {
        ...state,
        dstrictAdminSchoolsSectionsUsersData: action.payload,
      };

    }
    case AdminType.SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_REPORT: {
      return {
        ...state,
        dstrictAdminSchoolsSectionsUsersReportData: action.payload,
      };
    }

    default:
      return state;
  }
};

import React from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import asyncComponent from '../../../util/asyncComponent';
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {match} = this.props;
    
    return (
      <React.Fragment>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
          <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./routes/Admindashboard/Admindashboard'))} />
          <Route component={asyncComponent(() => import('components/Error404/Error404'))} />
        </Switch>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({settings, auth}) => {
  const {drawerType, navigationStyle} = settings;
  const {userRole} = auth;
  return {
    drawerType,
    navigationStyle,
    userRole,
  };
};
export default withRouter(connect(mapStateToProps, null)(Admin));
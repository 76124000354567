import * as dashboardType from '../../constants/ActionTypes';
import {all, fork, put, takeLatest, select} from 'redux-saga/effects';
import axios from 'util/Api';
import { saveDashboardData, dashboardLoader, saveDashboardCourseSData } from '../../redux/actions/Dashboard';
import { schoologyAppCoursesList } from '../../redux/actions/Auth';
import {errorHandler} from '../actions/Error';
import {push} from 'connected-react-router';

// Dashboard details fetch worker
export function* fetchDashboardSectionData(payload) {
  const reduxState = yield select();
  const {auth} = reduxState;
  const {ssoLoginDetails} = auth;
  try {
    yield put(dashboardLoader(true));
    const conditionalUrl = !!ssoLoginDetails?.section_id ? '/clever/instructor/sections?section_id='+ssoLoginDetails?.section_id : '/clever/instructor/sections';
    const ssoSectionId = yield axios.get(conditionalUrl);
    // const ssoSectionId = yield axios.get(`/clever/instructor/sections`);
    if (ssoSectionId.status === 200) {
      yield put(saveDashboardCourseSData({dashboardCoursesData: ssoSectionId.data}));
      yield put(dashboardLoader(false));
    } 
  } catch (error) {
    yield put(dashboardLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// Dashboard details fetch WATCHER
export function* fetchDashboardDataWatcher() {
  yield takeLatest(dashboardType.FETCH_DASHBOARD_DATA, fetchDashboardSectionData);
}

// DashboardCourses details fetch worker
export function* fetchUserCoursesData({payload}) {
  try {
    yield put(dashboardLoader(true));
    //const section_id = ssoSectionId.data[0].section_id
    const dashboardDetails = yield axios.get(`/clever/instructor/sections/${payload}/users`);
    if (dashboardDetails.status === 200) {
      yield put(saveDashboardData({dashboardTableData: dashboardDetails.data}));
      yield put(push('/app/instructor/course'));

      yield put(dashboardLoader(false));
    }
    yield put(dashboardLoader(false));
  } catch (error) {
    yield put(dashboardLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// DashboardCourses details fetch WATCHER
export function* fetchDashboardCoursesDataWatcher() {
  yield takeLatest(dashboardType.FETCH_DASHBOARD_COURSES_DATA, fetchUserCoursesData);
}

// fetch SchoologyApp Add course details
function* schoologyAddCourseData({payload: {context_id, client_id, deployment_id, section_id}}) {
  try {
    yield put(dashboardLoader(true));
    const payoadForAPI = {context_id: context_id, client_id: client_id, deployment_id: deployment_id, section_id: section_id}   
    const schoologyData = yield axios.post(`/schoology-add-course`, payoadForAPI );

    if (schoologyData.status === 200) {  
      yield put(push('/app/instructor/addcoursesuccess'));
      // ToastManager.success(schoologyData.data.message, null, 5000);
      yield put(schoologyAppCoursesList(true));
      yield put(dashboardLoader(false));
    }
    yield put(dashboardLoader(false));
  } catch (error) {
    yield put(dashboardLoader(false));
    yield put(errorHandler({error: error, page: ''}));
  }
}

export function* schoologyAddCourseDataWatcher() {
  yield takeLatest(dashboardType.FETCH_SCHOOLOGY_ADD_COURSE_DETAILS, schoologyAddCourseData);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchDashboardDataWatcher), fork(fetchDashboardCoursesDataWatcher), fork(schoologyAddCourseDataWatcher)]);
}


import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Countdown from 'react-countdown';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { userSignOut } from '../../redux/actions/Auth';
import DialogContent from '@material-ui/core/DialogContent';
import './IdleModal.scss';
const renderer = ({ hours, minutes, seconds, completed }) => {
  return (
    <span>
      0{minutes}:{seconds}
    </span>
  );
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class IdleTimeOutModal extends Component {
  closeModal = () => {
    this.props.onClose();
  };

  sessionCompleted = e => {
    this.props.userSignOut();
  };

  render() {
    const { classes, userSignOut, onClose, ...other } = this.props;

    return (
      <Dialog className="idleDialog" TransitionComponent={Transition} aria-labelledby="responsive-dialog-title" backdropclick="true" {...other}>
        <DialogTitle id="responsive-dialog-title " className="text-center">
          Session Expiration Warning
        </DialogTitle>
        <DialogContent style={{ maxWidth: '460px' }}>
          <DialogContentText className="text-dark text-justify ">
            Due to inactivity, your session will end in <Countdown onComplete={this.sessionCompleted} date={Date.now() + 20000} renderer={renderer} /> minutes.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mr-0 mb-3">
          <Button autoFocus onClick={this.closeModal} className="idealMbtn">
            Stay Logged In
          </Button>
          <Button
            onClick={() => {
              this.closeModal();
              this.props.userSignOut();
            }}
            autoFocus
            className="idealMbtn"
          >
            Sign Out Now
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignOut,
    },
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(IdleTimeOutModal);

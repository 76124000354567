import React from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import {Link} from 'react-router-dom';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {userSignOut} from '../../redux/actions/Auth';
import Box from '@mui/material/Box';
import DehazeIcon from '@material-ui/icons/Dehaze';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@mui/material/Typography';
import {capitalizeFirstLetter, dynamicThemeAllowMenus, getDefaultDomainDetails} from '../../util/Extra';
function AmerikoolerHeader(props) {
  const {clientConfigration, userRole, isSchoologyAPP,ssoLoginDetails} = props;
  const {serverConfig} = !!clientConfigration && clientConfigration;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);


  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
 
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <header className="header-wrap">
      <div className="container-full">
        <div className="row">
          <div className="col-md-6 col-7">
            <div className={'logo-wrap'}>
            {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'amerikoolerlogo') ? (
              <img className="logo-img" alt="Amerikooler" src={process.env.PUBLIC_URL + '../../img/amerikooler-logo.svg'} />)
          :    <img className="logo-img" alt="img-logo" src={serverConfig.institute_logo} />
            }
           
            </div>
          </div>
          <div className="col-md-6 col-5">
            <div className="profile-wrap">
              <Hidden only={'xs'}>
                {props.submitLoader || !props.amerikoolerProfileData ? (
                  <Skeleton className="header-skeleton" animation="wave" />
                ) : (
                  <Button  style={{background: `${isSchoologyAPP ? `#ffffff center right 20px` : `#ffffff url(/img/select-drop-arrow.svg) no-repeat center right 20px`}`}} disabled={isSchoologyAPP ? true : false} ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle}>
                    <Typography noWrap className="userName">
                      {`${!!props.amerikoolerProfileData && `${userRole === 'instructor' ? 'Hi, Instructor, ' : 'Hi, '}` + capitalizeFirstLetter(props.amerikoolerProfileData.first_name.split(' ')[0])}`}
                    </Typography>
                  </Button>
                )}

                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: '1000'}}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                       
                        <Box sx={{display: 'flex', flexDirection: 'column'}}> 
                        <>                                   
                          {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'Profile') && (
                            <Grow in={true}>
                            <Link to="profile" style={{textDecoration: 'none'}}>
                              <MenuItem onClick={handleClose}>Profile</MenuItem>
                            </Link>
                          </Grow>
                          )}


                            
                              {(props.location.pathname === '/app/student/course' && userRole === 'student' &&
                                getDefaultDomainDetails().themeColor === 'tdn-theme' &&
                                <Grow in={true}>
                                  <Link to="dashboard" style={{ textDecoration: 'none' }}>
                                    <MenuItem onClick={handleClose}>Back to Course</MenuItem>
                                  </Link>
                                </Grow>
                              )
                            }

                            {((props.location.pathname !== '/app/student/course' && userRole !== 'instructor') &&
                              (props.location.pathname !== '/app/admin/dashboard' && userRole !== 'admin')) && (
                                (props.location.pathname !== '/app/student/dashboard' && userRole !== 'student')) && (
                                <Grow in={true}>
                                  <Link to="course" style={{ textDecoration: 'none' }}>
                                    <MenuItem onClick={handleClose}>Back to Course</MenuItem>
                                  </Link>
                                </Grow>
                              )}
                          {(props.location.pathname === '/app/instructor/course' && userRole === 'instructor') && (
                            <>
                            <Grow in={true}>
                              <Link to="course" style={{textDecoration: 'none'}}>
                                <MenuItem onClick={handleClose}>Learner</MenuItem>
                              </Link>
                            </Grow>

                            <Grow in={true}>
                              <Link to="dashboard" style={{textDecoration: 'none'}}>
                                <MenuItem onClick={handleClose}>Back to Course </MenuItem>
                              </Link>
                            </Grow>
                            </>
                          )}
                
                          {!ssoLoginDetails && dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'Change Password') && (
                          <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={1000}>
                            <Link to="change-password" style={{textDecoration: 'none'}}>
                              <MenuItem onClick={handleClose}>Change Password</MenuItem>
                            </Link>
                          </Grow>
                          )}
                              <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
                                <Link to="/#" style={{ textDecoration: 'none' }}>
                                  <MenuItem
                                    onClick={() => {
                                      props.userSignOut();
                                    }}
                                  >
                                    Logout
                                  </MenuItem>
                                </Link>
                              </Grow>             
                          </>
                        </Box>
                    
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Popper>
              </Hidden>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <React.Fragment>
                      <DehazeIcon {...bindTrigger(popupState)} />
                      <Menu {...bindMenu(popupState)}>
                      
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <>
                          <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={500}>
                            <MenuItem onClick={popupState.close} className="text-dark">
                              {!!props.amerikoolerProfileData && 'Hi, ' + props.amerikoolerProfileData.first_name.split(' ')[0]}
                            </MenuItem>
                          </Grow>
                          {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'Profile') && (
                          <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={700}>
                            <Link to="profile" className="text-dark" style={{textDecoration: 'none'}}>
                              <MenuItem onClick={popupState.close}>Profile</MenuItem>
                            </Link>
                          </Grow>
                          )}

                          {/* {props.location.pathname !== '/app/student/course' && (
                            <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={700}>
                              <Link to="course" className="text-dark" style={{textDecoration: 'none'}}>
                                <MenuItem onClick={popupState.close}>Back to Course</MenuItem>
                              </Link>
                            </Grow>
                          )} */}
                               {(props.location.pathname === '/app/student/course' && userRole === 'student' &&
                                getDefaultDomainDetails().themeColor === 'tdn-theme' &&
                                <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={700}>
                                <Link to="dashboard" className="text-dark" style={{ textDecoration: 'none' }}>
                                  <MenuItem onClick={popupState.close}>Back to Course</MenuItem>
                                </Link>
                              </Grow>
                              )
                            }
                            {((props.location.pathname !== '/app/student/course' && userRole !== 'instructor') &&
                              (props.location.pathname !== '/app/admin/dashboard' && userRole !== 'admin')) && (
                                (props.location.pathname !== '/app/student/dashboard' && userRole !== 'student')) && (

                                <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={700}>
                                  <Link to="course" className="text-dark" style={{ textDecoration: 'none' }}>
                                    <MenuItem onClick={popupState.close}>Back to Course</MenuItem>
                                  </Link>
                                </Grow>
                              )}
                          {(props.location.pathname === '/app/instructor/course' && userRole === 'instructor') && (
                            <>
                            <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={700}>
                            <Link to="course" className="text-dark" style={{textDecoration: 'none'}}>
                              <MenuItem onClick={popupState.close}>Learner</MenuItem>
                            </Link>
                          </Grow>
                            <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={700}>
                            <Link to="dashboard" className="text-dark" style={{textDecoration: 'none'}}>
                              <MenuItem onClick={popupState.close}>Back to Course</MenuItem>
                            </Link>
                          </Grow>
                          </>
                          )}
                           {!ssoLoginDetails && dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'Change Password') && (
                          <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={1000}>
                            <Link to="change-password" className="text-dark" style={{textDecoration: 'none'}}>
                              <MenuItem onClick={popupState.close}>Change Password</MenuItem>
                            </Link>
                          </Grow>
                           )} 
                          {!isSchoologyAPP && (
                          <Grow in={true} style={{transformOrigin: '0 0 0'}} timeout={1000}>
                            <Link to="/#" className="text-dark" style={{textDecoration: 'none'}}>
                              <MenuItem
                                onClick={() => {
                                  sessionStorage.removeItem('component_route');
                                  props.userSignOut();
                                }}
                              >
                                Logout
                              </MenuItem>
                            </Link>
                          </Grow>
                          )}
                          </>
                        </Box>
                     
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Hidden>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignOut,
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, profile, router}) => {
  const {location} = router;
  const {ssoLoginDetails,employeeId, clientConfigration, userRole, isSchoologyAPP } = auth;
  const {amerikoolerProfileData, submitLoader} = profile;
  return {
    employeeId,
    clientConfigration,
    amerikoolerProfileData,
    submitLoader,
    location,
    userRole,
    isSchoologyAPP,
    ssoLoginDetails
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AmerikoolerHeader);

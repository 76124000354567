import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import configureStore, {history} from './redux/store';
import App from './containers/App';
import MaintenancePage from './app/routes/Maintenance/index';

export const store = configureStore();
const MainApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/maintenance" component={MaintenancePage} />
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);
export default MainApp;

/* istanbul ignore file */
import {keys, indexOf, isEmpty} from 'lodash';
import {NEXT, PREVIOUS} from '../constants/Constants';
import {capitalize} from 'lodash';
import axios from 'axios';
import config from '../config/config';
import io from 'socket.io-client';
import { useState } from 'react';
export const string_regex = /^[A-Za-z]{1}[-'\u2019 A-Za-z]*$/;
export const bankname_regex = /^[A-Za-z]{1}[-'.,&\u2019 A-Za-z]*$/;
export const alphanumeric_regex = /^[A-Za-z0-9]{1}[-'\u2019 A-Za-z0-9]*$/;
export const phone_regex = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
//At least one charcter in name
export const string_with_regx = /^(?=.*[a-zA-Z].*)([-'\u2019 a-zA-Z0-9]+)$/;

export const string_with_regx_new = /^(?=.*[a-zA-Z].*)([-'\u2019 a-zA-Z]+)$/;

export const name_regx = /^[a-zA-Z]+$/;
export const first_name_regx = /^[a-zA-Z]{3,100}$/;
export const last_name_regx = /^[a-zA-Z\s]{3,100}$/;
export const company_regx = /^[a-zA-Z'/ /']{1,255}$/;
// eslint-disable-next-line
export const zip_regx = /^[0-9\-]{5}$/;
export const phone_regx = /^[0-9 -]{12}$/;

// eslint-disable-next-line
export const phone_number_regex = /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/;
// export const number_regex = /^[0-9\b]+$/;
export const number_regex = /^[0-9]*$/;
export const employeeid_regex_ie = /[IE]-\d{7}/;
export const employeeid_regex_w = /[W]-\d{8}/;
// eslint-disable-next-line
export const email_regex = /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/;

export const email_regex_numbers = /^\d*[a-zA-Z]{1,}\d*/;

export const password_regex_old = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

export const password_regex = /^[A-Za-z0-9@#$&*()?]{5,16}$/;

export const zero_regex = /^0*[1-9][0-9]*$/;

export const zero_regex_new = /^0*[1-9][0-9]*$/;

export const grade_regex = /(<([^>]+)>)/gi;

export const white_space = /^\S*$/;

// DOB format
export const date_format = 'MM/DD/YYYY';

// Address street and city in upper case
export const getStartCase = value => {
  return value
    .split(' ')
    .map(capitalize)
    .join(' ')
    .trim();
};

// convert any unicode character to normal readable format
export const replaceWordChars = function(text) {
  var s = text;
  // smart single quotes and apostrophe
  s = s.replace(/[\u2018\u2019\u201A]/g, "'");
  // smart double quotes
  s = s.replace(/[\u201C\u201D\u201E]/g, '&quot;');
  // smart double quotes
  s = s.replace(/[\u2022]/g, '&bull;');
  // ellipsis
  s = s.replace(/\u2026/g, '...');
  // dashes
  s = s.replace(/[\u2013\u2014]/g, '-');
  // circumflex
  s = s.replace(/\u02C6/g, '^');
  // open angle bracket
  s = s.replace(/\u2039/g, '<');
  // close angle bracket
  s = s.replace(/\u203A/g, '>');
  // spaces
  s = s.replace(/[\u02DC\u00A0]/g, ' ');
  //\n
  s = s.replace(/[\n]/g, ' ');
  return s;
};

// Convert string value to boolean
export const convertToBool = string => {
  var isTrueSet = string === 'true';
  return isTrueSet;
};

// convert any number to doller format
export const formatNumber = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (negativeSign !== '-' ? '$' : '($') +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '') +
      (negativeSign !== '-' ? '' : ')')
    );
  } catch (e) {}
};

export function formatTime(seconds = 0, guide = seconds) {
  let s = Math.floor(seconds % 60);
  let m = Math.floor((seconds / 60) % 60);
  let h = Math.floor(seconds / 3600);
  const gm = Math.floor((guide / 60) % 60);
  const gh = Math.floor(guide / 3600);

  // handle invalid times
  if (isNaN(seconds) || seconds === Infinity) {
    // '-' is false for all relational operators (e.g. <, >=) so this setting
    // will add the minimum number of fields specified by the guide
    h = '-';
    m = '-';
    s = '-';
  }

  // Check if we need to show hours
  h = h > 0 || gh > 0 ? `${h}:` : '';

  // If hours are showing, we may need to add a leading zero.
  // Always show at least one digit of minutes.
  m = `${(h || gm >= 10) && m < 10 ? `0${m}` : m}:`;

  // Check if leading zero is need for seconds
  s = s < 10 ? `0${s}` : s;

  return h + m + s;
}

export function formatTimeForDashboard(seconds = 0, guide = seconds) {
  let s = Math.floor(seconds % 60);
  let m = Math.floor((seconds / 60) % 60);
  let h = Math.floor(seconds / 3600);
  const gm = Math.floor((guide / 60) % 60);
  const gh = Math.floor(guide / 3600);

  // handle invalid times
  if (isNaN(seconds) || seconds === Infinity) {
    // '-' is false for all relational operators (e.g. <, >=) so this setting
    // will add the minimum number of fields specified by the guide
    h = '-';
    m = '-';
    s = '-';
  }

  // Check if we need to show hours
  h = h > 0 || gh > 0 ? `${h}:` : '00:';

  // If hours are showing, we may need to add a leading zero.
  // Always show at least one digit of minutes.
  m = `${(h || gm >= 10) && m < 10 ? `0${m}` : m}:`;

  // Check if leading zero is need for seconds
  s = s < 10 ? `0${s}` : s;

  return h + m + s;
}

export function formatTimeToMilisecond(seconds = 0, guide = seconds) {
  let milisecond = Math.floor(seconds * 1000);
  // handle invalid times
  if (isNaN(seconds) || seconds === Infinity) {
    return false;
  }
  return milisecond;
}

export function formatObjectInSequence(obj, keyfromformate, basedonkey) {
  obj[keyfromformate] = Object.entries(obj[keyfromformate])
    .sort((prev, next) => {
      return prev[1][basedonkey] - next[1][basedonkey];
    })
    .reduce((acc, value) => {
      acc[value[0]] = value[1];
      return acc;
    }, {});
}

// function for return next previous array value from  given array
export function nextPreviousArrayValue(arr, index, type) {
  if (type === NEXT && !isEmpty(arr)) {
    return {
      step: !!arr[++index] ? arr[index] : null,
    };
  } else if (type === PREVIOUS && !isEmpty(arr)) {
    return {
      step: !!arr[--index] ? arr[index] : null,
    };
  }
  return {
    step: null,
  };
}

// function use for find next element of object
export function nextObject(resources, currentResourceKey) {
  var key = keys(resources);
  var index = indexOf(key, currentResourceKey);
  if (index + 1 < key.length) {
    return resources[key[index + 1]];
  } else {
    return null;
  }
}

// function used for find previous element of object
export function previousObject(review_question, currentQuestionKey) {
  var key = keys(review_question);
  var index = indexOf(key, currentQuestionKey);

  if (index - 1 < key.length) {
    return review_question[key[index - 1]];
  } else {
    return null;
  }
}

// find last element of object
export function lastElementOfObj(obj) {
  return obj[keys(obj)[keys(obj).length - 1]];
}
// Redierct URL for CDL Login Page
export const redirectToSANOFI = () => (window.location.href = 'http://localhost:3000');

// Error messages Hadler

export const errorMessageHandler = (errorObject, defaultMessage) => {
  return errorObject.data
    ? errorObject.data.errors
      ? errorObject.data.errors.error
      : errorObject.data.message
      ? errorObject.data.message
      : defaultMessage
    : defaultMessage;
};

// message for application
export const globalMessages = {
  loginAgainMessage: 'You need to login again with your new password.',
  passwordValidationErrorMessage: 'Passwords must contain at least 5 alphanumerical characters and can include symbols such as @#$&*()?',
  completedAllLessons: 'You have successfully completed all the lessons!',
  completedAllLessonsSubHeading: 'Your can return to the Lesson Library and practice your completed lessons anytime.',
  notCompletedAllLessons: 'You have not completed this lesson!',
  notCompletedAllLessonsSubHeading: 'Your can complete your next lessons please return to the Lesson Library.',
  completedResourses: 'Congratulations!',
  completedResoursesSubHeading: 'You have completed the training successfully.',
  continueToNextresourcesFromHMI: 'Click next button to go next resource.',
};
// Convert Seconds to minutes and hours (HH:mm:ss) in timer
export const SecondsTohhmmss = d => {
  d = Number(d);
  var hrs = Math.floor(d / 3600);
  var min = Math.floor((d % 3600) / 60);
  var sec = Math.floor((d % 3600) % 60);
  min = String(min).padStart(2, '0');
  hrs = String(hrs).padStart(2, '0');
  sec = String(sec).padStart(2, '0');
  return hrs + ':' + min + ':' + sec;
};
// export const noBack = (history, pathToStay) => {
//   console.log('Calling from extra');
//   // some time path is not changed i.e we strtictly changed path.
//   if (window.location.href.search(history.location.pathname) === -1) {
//     setTimeout(function() {
//       history.replace(pathToStay);
//     }, 100);
//   }
//   window.history.pushState(null, document.title, window.location.href);
//   window.addEventListener('popstate', function(event) {
//     window.history.pushState(null, document.title, window.location.href);
//   });
// };
export const normalizeEmployeeID = employeeId => employeeId && employeeId.replace(/[^IE\d]/g, '');

export const laravelEchoOptions = () => {
  const currentClientFromUrl = sessionStorage.getItem('currentClientFromUrl');
  return {
    broadcaster: 'socket.io',
    client: io,
    host: getDefaultDomainDetails(currentClientFromUrl).socket_url,
    auth: {
      headers: {
        Accept: 'application/json',
        'api-key':  getDefaultDomainDetails(currentClientFromUrl).api_key,
        authorization: !!axios.defaults.headers.common['Authorization']
          ? axios.defaults.headers.common['Authorization']
          : 'Bearer ' + sessionStorage.getItem('Authorization'),
      },
    },
  };
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const mapLineGraphObjectKeys = {
  no_of_times: 'Times',
  login_date: 'Date',
  logins_cnt: 'Login Counts',
};

// Convert date
export const convertDate = inputDate => {
  const date = new Date(inputDate);
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear()
  );
};

// clean an array if it has empty value
export const cleanArray = actual => {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
};

// Remove blank/empty attributes from an Object in Javascript
export const cleanObject = obj => {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
  }

  return obj;
};

// Get default config object : order sholud be proper for parameters
export const getDefaultDomainDetails = () => {
  const currentClientFromUrl = sessionStorage.getItem('currentClientFromUrl');
  const domain =  !isEmpty(currentClientFromUrl) ? currentClientFromUrl : mapHostNameToCode(window.location.host);
  // const domain = mapHostNameToCode('lms2.cdlschool.com');
  if (domain) {
    return !isEmpty(domain) ? config[domain] : null;
  }
  return null;
};

export const extractClientNameFromURL = () => {
  const clientName = window.location.href.split('/').includes('tdn') ? 'tdn' : '';
  const clientnameFromLocalStorage = sessionStorage.getItem('currentClientFromUrl');
  isEmpty(clientnameFromLocalStorage) && sessionStorage.setItem('currentClientFromUrl', clientName);

}
export const dynamicThemeAllowMenus = (themeValue, menuName) => {
  const tdnMeanus = ['logout', 'tdnsignindesc', 'tdnforgetpasdesc', 'Change Password', 'backToHome'];
  const paktolusMenus = ['logout', 'paktolussignindesc', 'paktolusforgetpasdesc', 'paktolusSignup'];
  const amerikoolerMenus = ['logout', 'Profile', 'Change Password', 'get certificate', 'amerikoolersignindesc', 'amerikoolercondition', 'amerikoolerforgetpasdesc', 'amerikoolerlogo', 'backToCourse'];
  const mapManus = {
    tdn: tdnMeanus,
    amerikooler : amerikoolerMenus,
    paktolus: paktolusMenus
  }
  if (mapManus[themeValue]?.includes(menuName)) {
    return true;
  } else {
    return false;
  }
}

export const useMergedState = (initialState) => {
  const [state, setState] = useState(initialState);
  const setMergedState = newState => setState(prevState => Object.assign({}, prevState, newState));
  return [state, setMergedState];
}

// get value for fetch config data
export const mapUserType = userType => {
  const userMapping = {
    5 : 'Learner',
    2: 'Instructor',
    3:  'Student',
    6 : 'Admin'
  };
  return userMapping[userType] || userType;
};

// User type id mapping
export const mapHostNameToCode = host => {
  const hostNameToCode = {
    'localhost:3000': 'tdn',
    'localhost': 'tdn',
    'tdn.dev.lms2.higherl.com': 'tdn',
    'localhost:3001': 'amerikooler',
    'localhost:3002': 'amerikooler',
    'amerikooler.lms2.higherl.com': 'amerikooler',
    'staging.lms2.higherl.com' : 'amerikooler',
    'main.d3kjypeemskj2q.amplifyapp.com': 'amerikooler',
    'lms2.portal.higherl.com': 'amerikooler',
    'tdn.lms2.higherl.com': 'tdn',
    'amerikooler.stage.lms2.higherl.com': 'amerikooler',
    'portal.stage.lms2.higherl.com': 'amerikooler',
    'tdn.stage.lms2.higherl.com': 'tdn',
    'tdn': 'tdn',
    'paktolus': 'paktolus',
    'stage-app.lms2.paktolus.io': 'paktolus',
    'app.lms2.paktolus.io': 'paktolus',  
  };
  return hostNameToCode[host] || host;
};
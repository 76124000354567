import * as akCourseType from '../../constants/ActionTypes';

// amerikooler get certificate
export const amerikoolerFetchCertificate = data => {
  return {
    type: akCourseType.AK_FETCH_CERTIFICATE,
    payload: data,
  };
};
// amerikooler certificate loader
export const amerikoolerCertificateLoader = data => {
  return {
    type: akCourseType.AK_CERTIFICATE_LOADER,
    payload: data,
  };
};
// amerikooler show details modal open/close
export const openCloseShowDetails = data => {
  return {
    type: akCourseType.OPEN_CLOSE_SHOW_DETAILS,
    payload: data,
  };
};

// amerikooler fetch course loader
export const amerikoolerFetchCourseDataLoader = data => {
  return {
    type: akCourseType.AK_FETCH_COURSE_DATA_LOADER,
    payload: data,
  };
};

// amerikooler course loader
export const amerikoolerCourseLoader = data => {
  return {
    type: akCourseType.AK_COURSE_LOADER,
    payload: data,
  };
};

// amerikooler fetch course data
export const amerikoolerFetchCourseData = data => {
  return {
    type: akCourseType.AK_FETCH_COURSE_DATA,
    payload: data,
  };
};

// TDN fetch course data
export const tdnFetchCourseData = data => {
  return {
    type: akCourseType.TDN_FETCH_COURSE_DATA,
    payload: data,
  };
};

// tdn fetch resource data
export const tdnFetchResourceData = data => {
  return {
    type: akCourseType.TDN_FETCH_RESOURCE_DATA,
    payload: data,
  };
};

// tdn fetch resource data
export const tdnLaunchData = data => {
  return {
    type: akCourseType.TDN_LAUNCH_DATA,
    payload: data,
  };
};

// amerikooler save course data
export const amerikoolerSaveCourseData = data => {
  return {
    type: akCourseType.AK_SAVE_COURSE_DATA,
    payload: data,
  };
};

// amerikooler save current unit
export const amerikoolerSaveCurrentUnit = data => {
  return {
    type: akCourseType.AK_SAVE_CURRENT_UNIT,
    payload: data,
  };
};

// amerikooler save current lesson
export const amerikoolerSaveCurrentLesson = data => {
  return {
    type: akCourseType.AK_SAVE_CURRENT_LESSON,
    payload: data,
  };
};

// amerikooler save current lesson
export const amerikoolerSaveCurrentResource = data => {
  return {
    type: akCourseType.AK_SAVE_CURRENT_RESOURCE,
    payload: data,
  };
};

// amerikooler next step
export const amerikoolerNextStep = () => {
  return {
    type: akCourseType.AK_NEXT_STEP,
  };
};

// amerikooler previous step
export const amerikoolerPreviousStep = () => {
  return {
    type: akCourseType.AK_PREVIOUS_STEP,
  };
};

// amerikooler next step loader
export const amerikoolerNextStepLoader = data => {
  return {
    type: akCourseType.AK_NEXT_STEP_LOADER,
    payload: data,
  };
};

// amerikooler previous step loader
export const amerikoolerPreviousStepLoader = data => {
  return {
    type: akCourseType.AK_PREVIOUS_STEP_LOADER,
    payload: data,
  };
};

// amerikooler save hmi data
export const amerikoolerSaveHmiData = data => {
  return {
    type: akCourseType.AK_SAVE_HMI_DATA,
    payload: data,
  };
};

// amerikooler save hmi steps data
export const amerikoolerSaveHmiSteps = data => {
  return {
    type: akCourseType.AK_SAVE_HMI_STEPS,
    payload: data,
  };
};

// amerikooler save vr data
export const amerikoolerSaveVrData = data => {
  return {
    type: akCourseType.AK_SAVE_VR_DATA,
    payload: data,
  };
};

// amerikooler save pdf data
export const amerikoolerSavePdfData = data => {
  return {
    type: akCourseType.AK_SAVE_PDF_DATA,
    payload: data,
  };
};

// amerikooler save video data
export const amerikoolerSaveVideoData = data => {
  return {
    type: akCourseType.AK_SAVE_VIDEO_DATA,
    payload: data,
  };
};

// amerikooler save scorm data
export const amerikoolerSaveScormData = data => {
  return {
    type: akCourseType.AK_SAVE_SCORM_DATA,
    payload: data,
  };
};

// amerikooler save scorm cmi data
export const akSaveScormCmiData = data => {
  return {
    type: akCourseType.AK_SAVE_SCORM_CMI_DATA,
    payload: data,
  };
};

// amerikooler save content html data
export const amerikoolerSaveContentHtmlData = data => {
  return {
    type: akCourseType.AK_SAVE_CONTENT_HTML_DATA,
    payload: data,
  };
};

// amerikooler save first and last resource id
export const amerikoolerSaveFirstandLastResourceId = data => {
  return {
    type: akCourseType.AK_SAVE_FIRST_AND_LAST_RESOURCE_ID,
    payload: data,
  };
};

// amerikooler update current unit
export const amerikoolerUpdateCurrentUnit = data => {
  return {
    type: akCourseType.AK_UPDATE_CURRENT_UNIT,
    payload: data,
  };
};

// amerikooler lock course(disabled next/previous button) so that user can't by passed next/resource
export const amerikoolerLockCourseBtnFunc = data => {
  return {
    type: akCourseType.AK_LOCK_COURSE_BUTTON,
    payload: data,
  };
};

// amerikooler vr full screen
export const amerikoolerVrFullScreen = data => {
  return {
    type: akCourseType.AK_VR_FULL_SCREEN,
    payload: data,
  };
};

// amerikooler hmi session end
export const amerikoolerHmiSessionEnd = () => {
  return {
    type: akCourseType.AK_HMI_SESSION_END,
  };
};

// save current time when a course load on screen
export const updateUserInfoTimeInterval = userDetails => {
  return {
    type: akCourseType.UPDATE_USER_INFO_TIME_INTERVAL,
    payload: userDetails,
  };
};

// save current time
export const updateUserInfoTime = userDetails => {
  return {
    type: akCourseType.UPDATE_USER_INFO_TIME,
    payload: userDetails,
  };
};

// save current time loader
export const updateUserInfoTimeLoader = data => {
  return {
    type: akCourseType.UPDATE_USER_INFO_TIME_LOADER,
    payload: data,
  };
};

// time will reset after every progress API called
export const updateVideoTimeAfterProgressApiCalled = userDetails => {
  return {
    type: akCourseType.UPDATE_VIDEO_TIME_AFTER_PROGRESS_API_CALLED,
    payload: userDetails,
  };
};

// when vr is completed will get response from socket
export const akFetchVrResourceCompleteData = userDetails => {
  return {
    type: akCourseType.AK_FETCH_VR_RESOURCE_COMPLETE_DATA,
    payload: userDetails,
  };
};

//fetch current video played seconds from server
export const akFetchVideoPlayedSeconds = data => {
  return {
    type: akCourseType.AK_FETCH_VIDEO_PLAYED_SECONDS,
    payload: data,
  };
};

//save current video played seconds from server
export const akSavehVideoPlayedSeconds = data => {
  return {
    type: akCourseType.AK_SAVE_VIDEO_PLAYED_SECONDS,
    payload: data,
  };
};

//save current video played seconds from server
export const akSavehUpdatedVideoPlayedSeconds = data => {
  return {
    type: akCourseType.AK_SAVE_UPDATED_VIDEO_PLAYED_SECONDS,
    payload: data,
  };
};

//update current video played seconds to server
export const akUpdateVideoPlayedSeconds = data => {
  return {
    type: akCourseType.AK_UPDATE_VIDEO_PLAYED_SECONDS,
    payload: data,
  };
};

//open/close certificate model
export const akShowHideCertificateModal = data => {
  return {
    type: akCourseType.AK_SHOW_HIDE_CERTIFICATE_MODAL,
    payload: data,
  };
};

// save progress of resource when course has only one resource and resource type is scorm
export const akSaveSingleScormResourcePorgress = data => {
  return {
    type: akCourseType.SAVE_SINGLE_SCORM_RESOURCE_PROGRESS,
    payload: data,
  };
};

// enable certificate button when scorm course is complete
export const akSingleScormResourceCertificateButtonEnable = data => {
  return {
    type: akCourseType.AK_SINGLE_SCORM_RESOURCE_CERTIFICATE_BUTTON_ENABLE,
    payload: data,
  };
};

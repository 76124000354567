import * as AdminType from '../../constants/ActionTypes';


export const fetchDstictAdminSchoolsData = data => {
  return {
    type: AdminType.FETCH_DISTRICT_ADMIN_SCHOOLS_DATA,
    payload: data,
  };
};

export const saveDstrictAdminSchoolsData = data => {
  return {
    type: AdminType.SAVE_DISTRICT_ADMIN_SCHOOLS_DATA,
    payload: data,
  };
};

export const adminLoader = val => {
  return {
  type: AdminType.ADMIN_LOADER,
  payload: val,
  };
};

export const fetchDstictAdminSchoolsSectionsData = data => {
  return {
    type: AdminType.FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_DATA,
    payload: data,
  };
};

export const saveDstrictAdminSchoolsSectionsData = data => {
  return {
    type: AdminType.SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_DATA,
    payload: data,
  };
};

export const fetchDstictAdminSchoolsSectionsUsersData = data => {
  return {
    type: AdminType.FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_DATA,
    payload: data,
  };
};
export const saveDstictAdminSchoolsSectionsUsersData = data => {
  return {
    type: AdminType.SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_DATA,
    payload: data,
  };
};
export const fetchDstictAdminSchoolsSectionsUsersReport = data => {
  return {
    type: AdminType.FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_REPORT,
    payload: data,
  };
};
export const saveDstictAdminSchoolsSectionsUsersReport = data => {
  return {
    type: AdminType.SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_REPORT,
    payload: data,
  };
};

import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import errorDetails from './Error';
import profileDetails from './Profile';
import AmerikoolerCourseSagas from './AmerikoolerCourse';
import DashboardSagas from './Dashboard';
import AdminSagas from './Admin';

export default function* rootSaga() {
  yield all([authSagas(), errorDetails(), profileDetails(), AmerikoolerCourseSagas(), DashboardSagas(), AdminSagas()]);
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchPconfig, userAutoSignIn, setInitUrl, setPconfig} from '../redux/actions/Auth';
import {setThemeColor} from '../redux/actions/Setting';
import {getDefaultDomainDetails, mapHostNameToCode} from '../util/Extra';
import {isEmpty} from 'lodash';
import Error404 from '../components/Error404/Error404';
import CircularProgress from '@material-ui/core/CircularProgress';
class ConfigfromServer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    const {clientConfigration, history} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;
    const componenRoute = sessionStorage.getItem('component_route');

    if (isEmpty(serverConfig)) {
      const domain = mapHostNameToCode(sessionStorage.getItem('currentClientFromUrl'));
      !isEmpty(domain) && this.props.setThemeColor(getDefaultDomainDetails().themeColor);
    }
    if (isEmpty(serverConfig)) {
      this.props.setPconfig({})
      this.props.fetchPconfig();
      //console.log('if fetch config configfromerver')
    } else {
      this.props.setInitUrl(componenRoute);
      history.push(componenRoute);
      //console.log('else Component route redirect from configfromerver')
    }

    // if (isEmpty(serverConfig)) {
    //   // set theme color according to client
    //   this.props.setThemeColor(getDefaultDomainDetails(currentClientFromUrl).themeColor);
    //   // call client-configuration API for client details
    //   this.props.fetchPconfig();
    // }
  }

  // componentWillMount() {
  //   const {authUser, userRole, history, clientConfigration} = this.props;
  //   const {serverConfig} = !!clientConfigration && clientConfigration;
  //   if (!isEmpty(serverConfig)) {
  //     history.push('/signin');
  //   } else {
  //     !isEmpty(authUser) && history.push(`/app/${userRole}`);
  //   }
  // }

  // static getDerivedStateFromProps(nextProps) {
  //   const {history, authUser, userRole} = nextProps;
  //   const {serverConfig} = !!nextProps.clientConfigration && nextProps.clientConfigration;
  //   isEmpty(serverConfig) && history.push('/configration');
  //   // if already user logged in (browser back navigation). and user login to app
  //   if (authUser !== null && userRole !== null) {
  //     history.push(`/app`);
  //   }

  //   return null;
  // }


  render() {
    const { serverConfig } = this.props;

    return (
      <React.Fragment>
        <div className="loader-view">
          <CircularProgress />
        </div>
        {!isEmpty(serverConfig) && <Error404 />}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userAutoSignIn,
      fetchPconfig,
      setThemeColor,
      setInitUrl,
      setPconfig
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, settings}) => {
  const {clientConfigration, authUser, alertMessage, showMessage, initURL, userRole} = auth;
  const {themeColor} = settings;
  return {clientConfigration, authUser, alertMessage, showMessage, initURL, themeColor, userRole};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigfromServer);

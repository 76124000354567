//App component for index pages
import React, {Component} from 'react';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import '../assets/vendors/style';
import defaultTheme from './themes/defaultTheme';
import MainApp from '../app/index';
import {setInitUrl, setPconfig} from '../redux/actions/Auth';
import RTL from '../util/RTL';
import asyncComponent from '../util/asyncComponent';
import SignIn from './SignIn';
import Oauth from './Oauth';
import SchoologyLaunch from './SchoologyLaunch';
import SchoologyApp from './SchoologyApp';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword/ChangePassword';
// import Course from 'app/routes/Instructor/routes/Course/Course';
import {bindActionCreators} from 'redux';
import {isEmpty} from 'lodash';
import Helmet from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfigfromServer from './ConfigfromServer';
import ExternalLinkServer from './ExternalLinkServer';
import LogOut from './LogOut';
import ToastContainer from '../components/ToastManager/ToastManager';
import {clearError} from '../redux/actions/Error';

const RestrictedRoute = ({component: Component, authUser, serverConfig, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      authUser && serverConfig ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: {
              from: props.location.pathname,
            },
          }}
        />
      )
    }
  />
);

const ServerConfigRoute = ({component: Component, configLoader, serverConfig, path, location, ...rest}) => {
  sessionStorage.setItem('component_route', location.pathname + location.search);
  return (
    <Route
      {...rest}
      render={props =>
       {
        return  !isEmpty(serverConfig) ? (
          <Component {...props} />
        ) : (
          !configLoader && (
            <Redirect
              to={{
                pathname: '/configration',
                state: {
                  from: props.location.pathname,
                },
              }}
            />
          )
        )
       }
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    document.body.classList.remove('amerikooler-theme');
    document.body.classList.add(this.props.themeColor);
  }

  componentDidMount() {
    this.props.clearError();
  }

  UNSAFE_componentWillMount() {
    // console.log(this.props.themeColor)
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  // Get page meta details for favicon, title and description
  getPageMetaDetails = () => {
    const {serverConfig} = !!this.props.clientConfigration && this.props.clientConfigration;
    if (serverConfig) {
      return !isEmpty(serverConfig.meta_details) && serverConfig.meta_details;
    }
    return false;
  };

  render() {
    const {configLoader, match, location, authUser, initURL, isDirectionRTL, userRole, signinStatus, clientConfigration} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;
    const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
    
    if(!!isMaintenanceMode) {
      return <Redirect to={'/maintenance'} />
    }
    
    if (location.pathname === '/') {
      if (isEmpty(serverConfig)) {
        return <Redirect to={'/configration'} />;
      } else if ((initURL === '' || initURL === '/' || initURL === '/signin') && signinStatus) {
        return <Redirect to={`/app/${userRole}`} />;
      } else if ((initURL === '' || initURL === '/') && authUser === null) {
        return <Redirect to={`/signin`} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    const applyTheme = createTheme(defaultTheme);
    // change theme according to client
    document.body.classList.remove('amerikooler-theme');
    document.body.classList.add(this.props.themeColor);
    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl');
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }
    return (
      <MuiThemeProvider theme={applyTheme}>
        {!configLoader && (
          <Helmet>
            <title>{this.getPageMetaDetails().institute_meta_title}</title>
            <meta name="description" content={this.getPageMetaDetails().institute_meta_description} />
            <link href={this.getPageMetaDetails().institute_meta_favicon} rel="shortcut icon" sizes="32x32" />
          </Helmet>
        )}
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <RTL>
            <div className="app-main">
              <div className="loader">
                <div className="text">
                  <CircularProgress color="inherit" />
                </div>
              </div>
              <Switch>
                <Route path="/health">
                    <h3 style={{width: '100%',display: 'flex',alignItems: 'center',justifyContent: 'center'}}> App is Healthy!</h3>
                </Route>
                <RestrictedRoute path={`${match.url}app`} authUser={authUser} serverConfig={serverConfig} configLoader={configLoader} component={MainApp} />
                {/* login from lms e-commerce website start */}
                <Route path={`${match.url}ext/login/:email_id/:user_id/:user_type_id/:jwt/:timestamp`} component={ExternalLinkServer} />
                {/* login from lms e-commerce website end */}
                <ServerConfigRoute serverConfig={serverConfig} configLoader={configLoader} path="/signin" component={SignIn} />
                <ServerConfigRoute serverConfig={serverConfig} configLoader={configLoader} path="/oauth" component={Oauth} />
                <ServerConfigRoute serverConfig={serverConfig} configLoader={configLoader} path="/schoologylaunch" component={SchoologyLaunch} />
                <ServerConfigRoute serverConfig={serverConfig} configLoader={configLoader} path="/schoologyapp" component={SchoologyApp} />
                <Route path="/logout" component={LogOut} />
                <ServerConfigRoute path="/password/reset/:authtoken" serverConfig={serverConfig} configLoader={configLoader} component={ChangePassword} />
                {/* <Route path="/instructor/course/" component={Course} /> */}
                <ServerConfigRoute serverConfig={serverConfig} configLoader={configLoader} path="/forgot-password" component={ForgotPassword} />
                <Route path="/configration" component={ConfigfromServer} />
                <ServerConfigRoute serverConfig={serverConfig} configLoader={configLoader} path="/" component={SignIn} />
                <Route component={asyncComponent(() => import('components/Error404/Error404'))} />
               
              </Switch>
            </div>
            <ToastContainer />
          </RTL>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setInitUrl,
      setPconfig,
      clearError,
    },
    dispatch,
  );
};

const mapStateToProps = ({settings, auth, router}) => {
  const {themeColor, sideNavColor, locale, isDirectionRTL} = settings;
  const {loader, configLoader, clientConfigration, authUser, initURL, signinStatus, userRole} = auth;
  const {location} = router;
  return {loader, configLoader, clientConfigration, themeColor, sideNavColor, locale, isDirectionRTL, authUser, initURL, signinStatus, userRole, location};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

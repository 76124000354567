import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, SHOW_HIDE_IDLE_MODAL} from '../../constants/ActionTypes';

export const fetchStart = () => {
  return {
    type: FETCH_START,
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS,
  };
};

export const fetchError = error => {
  return {
    type: FETCH_ERROR,
    payload: error,
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

// display modal when session is about to end
export const showIdleModal = val => {
  return {
    type: SHOW_HIDE_IDLE_MODAL,
    payload: val,
  };
};

// import generateExcel from "zipcelx";
import {saveAs} from 'file-saver';
import {Workbook} from 'exceljs';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const defaultCreator = 'MyEA.APP';
const defaultColumnWidth = 5;

export function getColumns(columns, defaultColumn) {
  return columns
    .filter(column => column.export !== false)
    .reduce((acc, curr) => {
      const {Header: header, accessor, id} = curr;
      const key = accessor || id;
      const width = curr.width || defaultColumn.width;
      acc[key] = {header, key, width: (width - 5) / 7.0};
      return acc;
    }, {});
}

export function getRows(rows, columns) {
  const keys = Object.keys(columns);
  return rows.map(row =>
    keys.reduce((acc, curr) => {
      if (row.values[curr] !== undefined) {
        acc[curr] = row.values[curr];
      } else {
        row.values['Course Status'] !== undefined && (acc['course_status'] = row.values['Course Status']);
        row.values['Time Spent'] !== undefined && (acc['time_spent'] = row.values['Time Spent']);
        row.values['Started Date'] !== undefined && (acc['started_date'] = row.values['Started Date']);
        row.values['Last Login'] !== undefined && (acc['last_login'] = row.values['Last Login']);
      }
      return acc;
    }, {}),
  );
}


// Function to convert the data into an Excel workbook
export const createExcelWorkbook = (columns, rows) => {
  const workbook =  new Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  // Add columns to the worksheet
  worksheet.columns = columns.map((column) => ({
    header: column.header,
    key: column.key,
    width: column.width || 15,
  }));

  // Add rows to the worksheet
  rows.forEach((row) => {
    worksheet.addRow(row);
  });
console.log(worksheet)
  return workbook;
};
 
export function exportToExcel(config, fileName) {
  const createdDate = new Date();
  const {creator = defaultCreator, lastModifiedBy = defaultCreator, created = createdDate, modified = createdDate} = config;
  const wb = new Workbook();
  // Set Workbook Properties
  wb.creator = creator;
  wb.lastModifiedBy = lastModifiedBy;

  wb.created = created;
  wb.modified = modified;
  // wb.lastPrinted = createdDate;
  const sheets = Array.isArray(config.sheets) ? config.sheets : config.sheet ? [config.sheet] : [];
  sheets.forEach(sheet => {
    const {sheetName, rows, columns, getValue, ...rest} = sheet;
    const options = {
      ...rest,
      pageSetup: rest.pageSetup
        ? {
            paperSize: 9,
            orientation: 'landscape',
            printTitlesRow: '1:1',
            ...rest.pageSetup,
          }
        : {
            paperSize: 9,
            orientation: 'landscape',
            printTitlesRow: '1:1',
          },
      views: Array.isArray(rest.views) ? rest.views.concat([{state: 'frozen', xSplit: 1}]) : [{state: 'frozen', xSplit: 1}],
      properties: {defaultRowHeight: 15, ...rest.properties},
    };
    const ws = wb.addWorksheet(sheetName, options);
    const wsColumns =
      rows.length > 0
        ? Object.keys(rows[0]).map(key => {
            if (columns && key in columns) {
              const column = columns[key];
              return {
                ...column,
                key,
                header: column.header || getColumnHeader(key),
                width: column.width || defaultColumnWidth,
              };
            }
            return {header: getColumnHeader(key), key};
          })
        : columns
        ? Object.entries(columns).map(entry => ({
            ...entry['1'],
            key: entry['0'],
            header: entry['1'].header || getColumnHeader(entry['0']),
            width: entry['1'].width || defaultColumnWidth,
          }))
        : [];
    ws.columns = wsColumns;
    // console.log(object)
    ws.autoFilter = {
      from: {
        row: 1,
        column: 1,
      },
      to: {
        row: rows.length + 1,
        column: wsColumns.length,
      },
    };

    const resultRows =
      typeof getValue === 'function'
        ? rows.map(row =>
            Object.entries(row).reduce((acc, curr) => {
              acc.push(getValue(curr['0'], curr['1']));
              return acc;
            }, []),
          )
        : rows.map(row => Object.values(row));

    ws.addRows(resultRows);

    ws.getRow(1).font = {bold: true};
  });

  return wb.xlsx.writeBuffer().then(buf => saveAs(new Blob([buf], {type: EXCEL_TYPE}), `${fileName}.xlsx`));
}

function getColumnHeader(key) {
  return key
    .split('_')
    .map(str => str.replace(/([A-Z])/g, ' $1').replace(/^./, str1 => str1.toUpperCase()))
    .join(' ');
}

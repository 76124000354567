import React from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import asyncComponent from '../../../util/asyncComponent';
import {getDefaultDomainDetails } from '../../../util/Extra';

class Learner extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {match, location, userRole} = this.props;
    if (location.pathname === `/app/${userRole}`) {
      if (getDefaultDomainDetails().themeColor === 'tdn-theme') {
        return <Redirect to={`/app/${userRole}/dashboard`} />;
      } else {
        return <Redirect to={`/app/${userRole}/course`} />;
      }
    }

    return (
      <React.Fragment>
        <Switch>
          <Route path={`${match.url}/profile`} component={asyncComponent(() => import('./routes/AmerikoolerUserProfile/AmerikoolerUserProfile'))} />
          <Route path={`${match.url}/change-password`} component={asyncComponent(() => import('./routes/ChangePassword/ChangePassword'))} />
          <Route path={`${match.url}/course`} component={asyncComponent(() => import('./routes/Course/Course'))} />
          <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./routes/StudentDashboard/StudentDashboard'))} />
          <Route component={asyncComponent(() => import('components/Error404/Error404'))} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {loader, authUser, signinStatus, userRole, clientConfigration} = auth;
  return {
    loader,
    authUser,
    signinStatus,
    userRole,
    clientConfigration,
  };
};

export default withRouter(connect(mapStateToProps, null)(Learner));

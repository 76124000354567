import * as coursesType from '../../constants/ActionTypes';
import {map, isEmpty} from 'lodash';
const INIT_STATE = {
  showDetailsOpenClose: false,
  akFetchCoursesDataLoader: false,
  akNextStepLoader: false,
  akPreviousStepLoader: false,
  akCourseData: null,
  akCurrentUnit: null,
  akCurrentLesson: null,
  akCurrentResource: null,
  akHmiData: null,
  akHmiSteps: null,
  akVrData: null,
  akPdfData: null,
  akVideoData: null,
  akContentHtmlData: null,
  akScormData: null,
  akScormCmiData: null,
  akFirstResourceId: null,
  akLastResourceId: null,
  akLockCourseBtn: false,
  akSimulatorFullScreen: false,
  akVideoTimeUpdate: 0,
  akCertificateLoader: false,
  akCourseLoader: false,
  akUpdateUserInfoTimeLoader: false,
  showHideCertificateModal: false,
  videoTrackingDetails: [],
  singleScormProgress: 0,
  singleScormCertificateButtonEnable: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case coursesType.OPEN_CLOSE_SHOW_DETAILS: {
      return {
        ...state,
        showDetailsOpenClose: action.payload,
      };
    }

    case coursesType.AK_FETCH_COURSE_DATA_LOADER: {
      return {
        ...state,
        akFetchCoursesDataLoader: action.payload,
      };
    }

    case coursesType.AK_SINGLE_SCORM_RESOURCE_CERTIFICATE_BUTTON_ENABLE: {
      return {
        ...state,
        singleScormCertificateButtonEnable: action.payload,
      };
    }

    case coursesType.AK_COURSE_LOADER: {
      return {
        ...state,
        akCourseLoader: action.payload,
      };
    }

    case coursesType.SAVE_SINGLE_SCORM_RESOURCE_PROGRESS: {
      return {
        ...state,
        singleScormProgress: action.payload,
      };
    }

    case coursesType.AK_SAVE_COURSE_DATA: {
      return {
        ...state,
        akCourseData: action.payload,
      };
    }

    case coursesType.AK_SAVE_CURRENT_UNIT: {
      return {
        ...state,
        akCurrentUnit: action.payload,
      };
    }

    case coursesType.AK_SAVE_CURRENT_LESSON: {
      return {
        ...state,
        akCurrentLesson: action.payload,
      };
    }

    case coursesType.AK_SAVE_CURRENT_RESOURCE: {
      return {
        ...state,
        akCurrentResource: action.payload,
      };
    }

    case coursesType.AK_NEXT_STEP_LOADER: {
      return {
        ...state,
        akNextStepLoader: action.payload,
      };
    }

    case coursesType.AK_PREVIOUS_STEP_LOADER: {
      return {
        ...state,
        akPreviousStepLoader: action.payload,
      };
    }

    case coursesType.AK_SAVE_HMI_DATA: {
      return {
        ...state,
        akHmiData: action.payload,
      };
    }

    case coursesType.AK_SAVE_HMI_STEPS: {
      return {
        ...state,
        akHmiSteps: action.payload.hmiSteps,
      };
    }

    case coursesType.AK_SAVE_VR_DATA: {
      return {
        ...state,
        akVrData: action.payload,
      };
    }

    case coursesType.AK_SAVE_PDF_DATA: {
      return {
        ...state,
        akPdfData: action.payload,
      };
    }

    case coursesType.AK_SAVE_VIDEO_DATA: {
      return {
        ...state,
        akVideoData: action.payload,
      };
    }

    case coursesType.AK_SAVE_SCORM_DATA: {
      return {
        ...state,
        akScormData: action.payload,
      };
    }

    case coursesType.AK_SAVE_SCORM_CMI_DATA: {
      return {
        ...state,
        akScormCmiData: action.payload,
      };
    }

    case coursesType.AK_SAVE_CONTENT_HTML_DATA: {
      return {
        ...state,
        akContentHtmlData: action.payload,
      };
    }

    case coursesType.AK_SHOW_HIDE_CERTIFICATE_MODAL: {
      return {
        ...state,
        showHideCertificateModal: action.payload,
      };
    }

    case coursesType.AK_SAVE_FIRST_AND_LAST_RESOURCE_ID: {
      return {
        ...state,
        akFirstResourceId: action.payload.firstResourceId,
        akLastResourceId: action.payload.lastResourceId,
      };
    }

    case coursesType.UPDATE_USER_INFO_TIME_LOADER: {
      return {
        ...state,
        akUpdateUserInfoTimeLoader: action.payload,
      };
    }

    case coursesType.AK_VR_FULL_SCREEN: {
      return {
        ...state,
        akSimulatorFullScreen: !action.payload,
      };
    }

    case coursesType.AK_UPDATE_CURRENT_UNIT: {
      const {resource_id, lesson_id, type} = action.payload;
      const {akCurrentUnit} = state;
      // const {lessons} = !!akCurrentUnit && akCurrentUnit;
      // const {resources} = !!akCurrentLesson && akCurrentLesson;
      // const activeLessonKey = findKey(lessons, ['lesson_id', lesson_id]);
      // const activeResourceKey = findKey(resources, ['resource_id', resource_id]);
      // const muteObj = {...akCurrentUnit};
      // let muteObjs = {...muteObj};
      // muteObjs.lessons[activeLessonKey].resources[activeResourceKey].is_complete = true;
      if (type === 'lesson_complete') {
        map(akCurrentUnit.lessons, lesson => {
          if (lesson.lesson_id === lesson_id) {
            lesson.is_complete = true;
          }
        });
      }
      map(akCurrentUnit.lessons, lesson => {
        if (!isEmpty(lesson.resources)) {
          map(lesson.resources, resource => {
            if (resource.resource_id === resource_id) {
              resource.is_complete = true;
            }
          });
        }
      });

      return {
        ...state,
        // last resource will be true then next button will disappear
        akCurrentResource: {
          ...state.akCurrentResource,
          is_complete: true,
        },
      };
    }

    case coursesType.AK_LOCK_COURSE_BUTTON: {
      return {
        ...state,
        akLockCourseBtn: action.payload,
      };
    }

    case coursesType.UPDATE_VIDEO_TIME_AFTER_PROGRESS_API_CALLED: {
      return {
        ...state,
        akVideoTimeUpdate: action.payload,
      };
    }

    case coursesType.AK_CERTIFICATE_LOADER: {
      return {
        ...state,
        akCertificateLoader: action.payload,
      };
    }

    case coursesType.AK_SAVE_VIDEO_PLAYED_SECONDS: {
      return {
        ...state,
        videoTrackingDetails: action.payload,
      };
    }

    case coursesType.AK_SAVE_UPDATED_VIDEO_PLAYED_SECONDS: {
      const {resource_id, time} = action.payload;
      const {videoTrackingDetails} = state;
      if (!isEmpty(videoTrackingDetails) && !!resource_id && time > 0) {
        const updatedTrackingDetails = videoTrackingDetails.map(element => {
          if (element.resource_id === resource_id) {
            return {
              ...element,
              last_content_tracker_detail: {
                ...element.last_content_tracker_detail,
                optional_details: time,
              },
            };
          }
          return element;
        });
        if (!isEmpty(updatedTrackingDetails)) {
          return {
            ...state,
            videoTrackingDetails: updatedTrackingDetails,
          };
        }
      }
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

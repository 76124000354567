import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import { userSignIn, flushAppDetails, fetchTdnSchoologylaunchDetails, schoologyAppCoursesList, saveSchoologySsoLoginDetails } from '../redux/actions/Auth';
import { saveAmerikoolerProfileData } from '../redux/actions/Profile';
import getQueryParams from 'util/QueryString';
import { extractClientNameFromURL, getDefaultDomainDetails} from '../util/Extra';
import { setThemeColor } from '../redux/actions/Setting';
import CircularProgress from '@material-ui/core/CircularProgress';


class SchoologyApp extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  componentDidMount() {
    const { fetchTdnSchoologylaunchDetails, schoologyAppCoursesList, saveSchoologySsoLoginDetails } = this.props;
    const userCode = getQueryParams(window.location.search, 'code');
    const userEmail = getQueryParams(window.location.search, 'email');
    const username = getQueryParams(window.location.search, 'name');
    const userTypeId = getQueryParams(window.location.search, 'user_type_id');
    const context_id = getQueryParams(window.location.search, 'context_id');
    const client_id = getQueryParams(window.location.search, 'client_id');
    const deployment_id = getQueryParams(window.location.search, 'deployment_id');
    const schoologyParams = { code: userCode, email: userEmail, name: username, user_type_id: userTypeId,  context_id: context_id }
    if (!isEmpty(userCode)) {
      console.log('Component did mount')
      saveSchoologySsoLoginDetails({'context_id': context_id, 'client_id': client_id, 'deployment_id': deployment_id })
      fetchTdnSchoologylaunchDetails(schoologyParams);
      schoologyAppCoursesList(true);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    console.log('get DerivedState FromProps')
    const { history, clientConfigration, fetchTdnSchoologylaunchDetails } = nextProps;
    extractClientNameFromURL();
    const { serverConfig } = !!clientConfigration && clientConfigration;
    const userCode = getQueryParams(window.location.search, 'code');
    const userEmail = getQueryParams(window.location.search, 'email');
    const username = getQueryParams(window.location.search, 'name');
    const userTypeId = getQueryParams(window.location.search, 'user_type_id');
    const context_id = getQueryParams(window.location.search, 'context_id');
    const client_id = getQueryParams(window.location.search, 'client_id');
    const deployment_id = getQueryParams(window.location.search, 'deployment_id');
    const schoologyParams = { code: userCode, email: userEmail, name: username, user_type_id: userTypeId,  context_id: context_id }
    console.log('context_id', context_id)
    console.log('client_id', client_id)
    console.log('deployment_id', deployment_id)
    if (!isEmpty(userCode)) {
      saveSchoologySsoLoginDetails({'context_id': context_id, 'client_id': client_id, 'deployment_id': deployment_id })
      fetchTdnSchoologylaunchDetails(schoologyParams);
      schoologyAppCoursesList(true);
    }
    isEmpty(serverConfig) && history.push('/configration');
    const currentClientFromUrl = sessionStorage.getItem('currentClientFromUrl');
    nextProps.setThemeColor(getDefaultDomainDetails(currentClientFromUrl).themeColor);
    //if (authUser !== null && signinStatus && userRole !== null && saveSchoologyAppDetails !== null) {
      //history.push(`/app/${userRole}/courselist`);
    //}
    return null;
  }
  render() {
    const { alertMessage, showMessage} = this.props;
    return (
      <div className={`app-container pre-signup-wrapper`}>
        <div className="app-main-container">
          <main className="app-main-content-wrapper email-verification">
            <div className="app-main-content">
              <div className="loader-view">
                <CircularProgress />
              </div>
              {showMessage && NotificationManager.error(alertMessage)}
              <NotificationContainer />
            </div>
          </main>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignIn,
      flushAppDetails,
      fetchTdnSchoologylaunchDetails,
      setThemeColor,
      schoologyAppCoursesList,
      saveAmerikoolerProfileData,
      saveSchoologySsoLoginDetails,
    },
    dispatch,
  );
};

const mapStateToProps = ({ auth, profile }) => {
  const { loader, authUser, signinStatus, userRole, clientConfigration, schoologyUserDetails, saveSchoologyAppDetails } = auth;
  const {amerikoolerProfileData} = profile;

  return {
    loader,
    authUser,
    signinStatus,
    userRole,
    clientConfigration,
    schoologyUserDetails,
    amerikoolerProfileData,
    saveSchoologyAppDetails
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SchoologyApp);

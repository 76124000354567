import {all, fork, put, delay, takeEvery} from 'redux-saga/effects';
import * as agetDetailsActions from 'constants/ActionTypes';
import {userSignOut} from '../actions/Auth';
// import {bugsnagError} from '../../util/bugsnagUtils';
// import {UIAPIError} from '../../util/errorUtils';
// import {errorMessageHandler} from '../../util/Extra';
import {ToastManager} from '../../components/ToastManager/ToastManager';
import {clearError, showError} from '../actions/Error';

// function* errorDetails({payload}) {
//   if (payload.response && payload.response.status === 401) {
//     yield ToastManager.error(payload.response.data.message, null, 3000);
//     yield bugsnagError(new UIAPIError(errorMessageHandler(payload.response, 'API Error')), payload);
//   } else if (payload.response && payload.response.status === 406) {
//     yield ToastManager.error(payload.response.data.message, null, 3000);
//     yield bugsnagError(new UIAPIError(errorMessageHandler(payload.response, 'User Logout Because on Another Active Session Error')), payload);
//     yield put(push('/logout'));
//     yield put(userSignOut());
//   } else if (payload.response && payload.response.status === 500) {
//     yield ToastManager.error(payload.response.data.message, null, 3000);
//     bugsnagError(new UIAPIError(errorMessageHandler(payload.response, 'Server Error')), payload);
//   } else if (payload.response && payload.response.status === 403) {
//     //403 user not verified
//     yield ToastManager.error(payload.response.data.errors.error, null, 3000);
//     bugsnagError(new UIAPIError(errorMessageHandler(payload.response, 'User Not Verified')), payload);
//   } else {
//     if (payload.response) {
//       bugsnagError(new UIAPIError(errorMessageHandler(payload.response, 'Unknown Error')), payload);
//       yield ToastManager.error(payload.response.data.message, null, 3000);
//     } else {
//       bugsnagError(new UIAPIError(errorMessageHandler(payload.message, 'Network Timeout Error')), payload);
//       yield ToastManager.error(payload.message, null, 3000);
//     }
//   }
// }
function* errorDetails({payload}) {
  if (payload.error.response && payload.error.response.status === 401) {
    if (payload.page === '') {
      yield ToastManager.error(payload.error.response.data.message);
    } else {
      yield put(showError({message: payload.error.response.data.message, page: payload.page}));
    }
    // yield bugsnagError(new UIAPIError(errorMessageHandler(payload.error.response, 'API Error')), payload);
  } else if (payload.error.response && payload.error.response.status === 406) {
    if (payload.page === '') {
      yield ToastManager.error(payload.error.response.data.message);
    } else {
      yield put(showError({message: payload.error.response.data.message, page: payload.page}));
    }
    // yield bugsnagError(new UIAPIError(errorMessageHandler(payload.error.response, 'User Logout Because on Another Active Session Error')), payload);
    yield put(userSignOut());
  } else if (payload.error.response && payload.error.response.status === 500) {
    if (payload.page === '') {
      yield ToastManager.error(payload.error.response.data.message);
    } else {
      yield put(showError({message: payload.error.response.data.message, page: payload.page}));
    }
    // bugsnagError(new UIAPIError(errorMessageHandler(payload.error.response, 'Server Error')), payload);

  } else if (payload.error.response && payload.error.response.status === 403) {
      //403 user not verified
    if (payload.page === '') {
      yield ToastManager.error(payload.error.response.data.message);
    } else {
      yield put(showError({message: payload.error.response.data.message, page: payload.page}));
    }
    // bugsnagError(new UIAPIError(errorMessageHandler(payload.response, 'User Not Verified')), payload);  
  } else {
    if (payload.error.response) {
      // bugsnagError(new UIAPIError(errorMessageHandler(payload.error.response, 'Unknown Error')), payload);
      if (payload.page === '') {
        yield ToastManager.error(payload.error.response.data.message);
      } else {
        yield put(showError({message: payload.error.response.data.message, page: payload.page}));
      }
    } else {
      // bugsnagError(new UIAPIError(errorMessageHandler(payload.error.message, 'Network Timeout Error')), payload);
      if (payload.page === '') {
        yield ToastManager.error(payload.error.message);
      } else {
        yield put(showError({message: payload.error.message, page: payload.page}));
      }
    }
  }
  yield delay(7000);
  yield put(clearError());
}

export function* errorDetailsWatcher() {
  yield takeEvery(agetDetailsActions.ERROR_DETAILS, errorDetails);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(errorDetailsWatcher)]);
}

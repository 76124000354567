import {all, fork, put, takeEvery, delay, takeLatest, select } from 'redux-saga/effects';
import axios from 'util/Api';
import {push} from 'connected-react-router';
import * as authType from 'constants/ActionTypes';
import {
  setInitUrl,
  setPconfig,
  userSignInSuccess,
  userSignOutSuccess,
  authLoaderHide,
  authLoaderShow,
  setConfigLoader,
  setSupervisorId,
  saveSsoLoginDetails,
  setPasswordUpdateLoader,
  dropdownCityListSuccess,
  dropdownStateListSuccess,
  cityStateDataByZipSuccess,
  signupUserEmailVerified,
  resendEmailVerificationLoaderFunc,
  changePasswordUpdateFunc,
  changePasswordCompleted,
} from '../actions/Auth';
import {errorHandler} from '../actions/Error';
import {isEmpty} from 'lodash';
import {SUPERVISOR, INSTRUCTOR, STUDENT, ADMIN} from 'constants/Constants';
import {getDefaultDomainDetails} from '../../util/Extra';
import {setThemeColor} from '../actions/Setting';
import {encryptData} from '../../util/Crypto';
import {ToastManager} from '../../components/ToastManager/ToastManager';

function* signInUser({payload: {loginDetails}}) {
  try {
    yield put(authLoaderShow());
    const sentFormData = {
      ...loginDetails,
      password: encryptData(loginDetails.password),
      // encrypted_password: 1,
    };
    const userDetails = yield axios.post('/login', sentFormData);
    if (!!userDetails && userDetails.data) {
      if (userDetails.status === 200) {
        const details = userDetails.data;
        let userId = '';
        let jwt = '';
        let refreshJwt = '';
        let emailId = null;
        let userRole = null;
        let studentId = null;
        userId = !!details && details.userId;
        studentId = !!details && details.default_student_id;
        jwt = !!details && details.jwt;
        emailId = !!details && details.emailId;
        userRole =
          !!details && details.userTypeId === SUPERVISOR
            ? 'learner'
            : details.userTypeId === INSTRUCTOR
            ? 'instructor'
            : details.userTypeId === STUDENT
            ? 'student'
            : details.userTypeId === ADMIN
            ? 'admin'
            : null;

        sessionStorage.setItem('Authorization', jwt);
        sessionStorage.setItem('user_id', emailId);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

        yield put(
          userSignInSuccess({
            authUser: emailId,
            userId,
            token: jwt,
            userRole,
            studentId,
            refreshToken: refreshJwt,
          }),
        );
        yield put(
          setSupervisorId({
            userId: userId,
          }),
        );
      }
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: 'SignIn'}));

  }
  yield put(authLoaderHide());
}

export function* signInUserWatcher() {
  yield takeEvery(authType.SIGNIN_USER, signInUser);
}

function* ssoLogin({payload: {code}}) {
  try {
    yield put(authLoaderShow());
    const userDetails = yield axios.get(`/clever/sso-login/${code}`);
    if (!!userDetails && userDetails.data) {
      if (userDetails.status === 200) {
        const details = userDetails.data;
        let userId = '';
        let jwt = '';
        let refreshJwt = '';
        let emailId = null;
        let userRole = null;
        let studentId = null;
        studentId = !!details && details.default_student_id;
        userId = !!details && details.userId;
        jwt = !!details && details.jwt;
        emailId = !!details && details.emailId;
        userRole =
          !!details && details.userTypeId === SUPERVISOR
            ? 'learner'
            : details.userTypeId === INSTRUCTOR
            ? 'instructor'
            : details.userTypeId === STUDENT
            ? 'student'
            : details.userTypeId === ADMIN
            ? 'admin'
            : null;

        sessionStorage.setItem('Authorization', jwt);
        sessionStorage.setItem('user_id', emailId);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

        yield put(
          userSignInSuccess({
            authUser: emailId,
            userId,
            token: jwt,
            userRole,
            refreshToken: refreshJwt,
          }),
        );
        yield put(
          setSupervisorId({
            userId: userId,
          }),
        );
        yield put(
          saveSsoLoginDetails({
            studentId: studentId,
            authUser: emailId,
            userId: userId,
            token: jwt,
          }),
        );
      }
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
    yield delay(1000)
    sessionStorage.removeItem('component_route');
    sessionStorage.removeItem('currentClientFromUrl');
    yield put(userSignOutSuccess());
    yield put(setInitUrl('logout'));
    yield put(push('/logout'));
  }
  yield put(authLoaderHide());
}
export function* ssoLoginWatcher() {
  yield takeEvery(authType.FETCH_TDN_SSO_LOGIN_DETAILS, ssoLogin);
}

function* schoologyLaunch({payload: {code, email, name, user_type_id, context_id}}) {
  try {    
    yield put(authLoaderShow());
    const payoadForAPI = {code: code, email: email, name: name, user_type_id: user_type_id, context_id}   
    const launchCode = yield axios.post(`/schoology-user-login-details`, payoadForAPI );
  if (!!launchCode && launchCode.data) {
      if (launchCode.status === 200) {
        const details = launchCode.data;
        let userId = '';
        let jwt = '';
        let refreshJwt = '';
        let emailId = null;
        let userRole = null;
        let studentId = null;
        let section_id = null;
        const userTypeId = !!details && details.userTypeId ? Number(details.userTypeId) : '';
        studentId = !!details && details.default_student_id;
        userId = !!details && details.userId;
        jwt = !!details && details.jwt;
        emailId = !!details && details.emailId;
        section_id = !!details && details.section_id;
        userRole =
          userTypeId === SUPERVISOR
            ? 'learner'
            : userTypeId === INSTRUCTOR
            ? 'instructor'
            : userTypeId === STUDENT
            ? 'student'
            : userTypeId === ADMIN
            ? 'admin'
            : null;

        sessionStorage.setItem('Authorization', jwt);
        sessionStorage.setItem('user_id', emailId);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

        yield put(
          userSignInSuccess({
            authUser: emailId,
            userId,
            token: jwt,
            userRole,
            refreshToken: refreshJwt,
          }),
        );
        yield put(
          setSupervisorId({
            userId: userId,
          }),
        );
      yield put(
        saveSsoLoginDetails({
          studentId: studentId,
          authUser: emailId,
          userId: userId,
          token: jwt,
          section_id: section_id
        }),
      );
        const routPath = sessionStorage.getItem('component_route');
        const allowedORNot = !!routPath && routPath.includes('schoologyapp') ? true : false;
      if (userRole === 'instructor' && allowedORNot) { yield put(push(`/app/${userRole}/courselist`)) }
    }
  }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
    yield delay(1000)
    sessionStorage.removeItem('component_route');
    sessionStorage.removeItem('currentClientFromUrl');
    yield put(userSignOutSuccess());
    yield put(setInitUrl('logout'));
    yield put(push('/logout'));
  }
  yield put(authLoaderHide());
}

export function* schoologyLaunchWatcher() {
  yield takeEvery(authType.FETCH_TDN_SCHOOLOGY_LAUNCH_DETAILS, schoologyLaunch);
}


function* loginFromECommerceSiteWorker({payload: {emailId, jwt, userId, userTypeId}}) {
  try {
    yield put(authLoaderShow());
    // set theme color according to client
    const currentClientFromUrl = sessionStorage.getItem('currentClientFromUrl');
    yield put(setThemeColor(getDefaultDomainDetails(currentClientFromUrl).themeColor));
    // call client-configuration API for client details
    // const configData = yield axios.get(`/client-configuration`);
    const configData = yield axios.get(`/institute-configuration`);
    if (!isEmpty(configData.data) && configData.status === 200) {
      const data = configData.data;
      yield put(setPconfig(data));

      if (!!emailId && !!jwt && !!userId && !!userTypeId) {
        const userRole = userTypeId === SUPERVISOR ? 'learner' : userTypeId === INSTRUCTOR ? 'instructor' : userTypeId === STUDENT ? 'student': userTypeId === ADMIN ? 'admin' : null;
        sessionStorage.setItem('Authorization', jwt);
        sessionStorage.setItem('user_id', emailId);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

        yield put(
          userSignInSuccess({
            authUser: emailId,
            userId,
            token: jwt,
            userRole,
          }),
        );
        yield put(
          setSupervisorId({
            userId: userId,
          }),
        );
        // redirect to course page
        if (!isEmpty(userRole)) yield put(push(`/app/${userRole}`));
      }
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
  yield put(authLoaderHide());
}

export function* loginFromECommerceSiteWatcher() {
  yield takeEvery(authType.LOGIN_FROM_E_COMMERCE_SITE, loginFromECommerceSiteWorker);
}

function* fetchPartnerConfigDetails() {
  try {
    yield put(setConfigLoader(true));
    const redirectPath =
      !!sessionStorage.getItem('component_route') && sessionStorage.getItem('component_route') !== '' ? sessionStorage.getItem('component_route') : '/';
    // const configData = yield axios.get(`/client-configuration`);
    const configData = yield axios.get(`/institute-configuration`);
    if (!isEmpty(configData.data) && configData.status === 200) {
      const data = configData.data;
      yield put(setPconfig({...data, institute_subdomain_url: window.location.origin}));
    }
    yield put(push(redirectPath));
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
  yield put(setConfigLoader(false));
}

export function* fetchPconfigDetailsWatcher() {
  yield takeEvery(authType.FETCH_PCONFIG, fetchPartnerConfigDetails);
}

function* signOutUser() {
  try {
    const logoutDetails = yield axios.get('/logout');
    sessionStorage.removeItem('component_route');
    if (logoutDetails.status === 200) {
      sessionStorage.removeItem('Authorization');
      sessionStorage.removeItem('user_id');
      sessionStorage.removeItem('state');
      delete axios.defaults.headers.common['Authorization'];
      sessionStorage.removeItem('component_route');
      sessionStorage.removeItem('currentClientFromUrl');
      yield put(userSignOutSuccess());
      yield put(setInitUrl('logout'));
      yield put(push('/logout'));
    }
  } catch (error) {
    if (!error.response) {
      // yield put(errorHandler(error));
      yield put(errorHandler({error: error, page: ''}));
    }
  }
}

export function* signOutWatcher() {
  yield takeEvery(authType.SIGNOUT_USER, signOutUser);
}

// function* forgotPassword({payload: {loginDetails}}) {
//   try {
//     yield put(authLoaderShow());
//     const sentFormData = JSON.stringify(loginDetails);
//     const formData = new FormData();
//     formData.append('sentFormData', sentFormData);
//     const response = yield axios.post('/app.php/ws_forgot_password', formData);
//     if (response.data) {
//       ToastManager.success(response.data.message);
//       yield delay(1000);
//       yield put(push('/signin'));
//     }
//   } catch (error) {
//     yield put(errorHandler(error));
//   }
//   yield put(authLoaderHide());
// }
// export function* forgotPasswordWatcher() {
//   yield takeEvery(authType.FORGOT_PASSWORD_START, forgotPassword);
// }

// forget-password

//fetch forgotPassword api
function* forgotPassword({payload: {email, recaptchaToken}}) {
  try {
    yield put(authLoaderShow());
    const formData = {
      email,
    };
    const myBoolean = false;
    // const myBoolean = true;
    let verifyingCaptcha;
    if (myBoolean) {
      verifyingCaptcha = yield axios.post('/verify-captcha', {token: recaptchaToken});
    }
    if (myBoolean === false || verifyingCaptcha.data) {
      const response = yield axios.post('/forgot-password-request', formData);
      if (response.data) {
        // ToastManager.success(response.data.message);
        ToastManager.success('Your Reset Password link has been sent to your email successfully',null, 5000);

        yield delay(1000);
        yield put(push('/signin'));
      }
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: 'ForgetPassword'}));

    
  } finally {
    yield put(authLoaderHide());
  }
}

export function* forgotPasswordWatcher() {
  yield takeEvery(authType.FORGOT_PASSWORD_START, forgotPassword);
}

// update password by email link url
function* sendUpdatePassword({payload}) {
  yield put(setPasswordUpdateLoader(true));
  const reduxState = yield select();
  const {auth} = reduxState;
  const {passwordTokenDetails, updateDefaultPassword} = auth;
  const {token, email} = passwordTokenDetails;

  try {
    // send updated password data
    const response = yield axios.post('/reset-password', {
      token: token,
      email: email,
      ...payload,
    });

    // provide user feedback related to updated password
    if (response.status === 200 && response.data) {
      yield put(changePasswordCompleted(true));
      // ToastManager.success(response.data.message);
      ToastManager.success('Your Password has been changed Successfully !',null, 5000);
      yield put(push('/signin'));

      if (updateDefaultPassword) {
        yield put(userSignInSuccess({updateDefaultPassword: false}));
      }
    }
    // check response
  } catch (error) {
    yield put(errorHandler({error: error, page: 'changepassword'}));
    // yield put(errorHandler(error));

  }
  yield put(setPasswordUpdateLoader(false));
}

export function* updatePasswordWatcher() {
  yield takeEvery(authType.CHANGE_PASSWORD_FROM_EMAIL_LINK, sendUpdatePassword);
}

function* changePasswordWorker({payload}) {
  try {
    yield put(setPasswordUpdateLoader(true));
    const response = yield axios.post('/change-password', {...payload});
    if (response.status === 200) {
      // ToastManager.success(response.data);
      yield put(changePasswordUpdateFunc(true));
      // yield put(push('/signin'));
    }
  } catch (error) {
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
  yield put(setPasswordUpdateLoader(false));
}
export function* changePasswordWatcher() {
  yield takeEvery(authType.UPDATE_PASSWORD, changePasswordWorker);
}

//Dropdown states list fetch worker
export function* fetchDropdownStateListWorker({payload}) {
  try {
    //yield put(certifiedInstallerShowLoader());
    const country = payload;
    const certifiedInstallerResponse = yield axios.get(`/get-states-by-country/${country}`);

    if (certifiedInstallerResponse.data) {
      yield put(dropdownStateListSuccess(certifiedInstallerResponse.data));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
  //yield put(certifiedInstallerHideLoader());
}

//Dropdown states list fetch watcher
export function* fetchDropdownStateListWatcher() {
  yield takeEvery(authType.FETCH_DROPDOWN_STATE_LIST, fetchDropdownStateListWorker);
}

// fetch city drodown List
export function* fetchDropdownCityListWorker({payload}) {
  try {
    const state = payload;
    const cityListResponse = yield axios.get(`/get-cities/US/${state}`);

    if (cityListResponse.data) {
      yield put(dropdownCityListSuccess(cityListResponse.data));
    }
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
}

//  Dropdown city list fetch watcher
export function* fetchDropdownCityListWatcher() {
  yield takeEvery(authType.FETCH_DROPDOWN_CITY_LIST, fetchDropdownCityListWorker);
}

// fetch city state by Zipcode
export function* fetchCityStateNameByZipcodeWorker({payload}) {
  try {
    const zip = payload;
    const cityStateData = yield axios.get(`/get-city-state-by-zipcode/${zip}`);

    if (cityStateData.data) {
      yield put(cityStateDataByZipSuccess(cityStateData.data));
    }
  } catch (error) {
    yield put(cityStateDataByZipSuccess(null));

    if (!error.response) {
      yield put(errorHandler({error: error, page: ''}));
    }
  }
}

//  city state by Zipcode fetch watcher
export function* fetchCityStateByZipWatcher() {
  yield takeEvery(authType.FETCH_CITY_STATE_BY_ZIP, fetchCityStateNameByZipcodeWorker);
}

// resend link for email verification
export function* resendEmailVerificationLinkFunction({payload}) {
  try {
    yield put(resendEmailVerificationLoaderFunc(true));
    const resendEmailVerification = yield axios.post(`/email/resend-email-verification-notification`, payload);

    if (resendEmailVerification.data) {
      ToastManager.success(resendEmailVerification.data, null, 6000);
      yield put(signupUserEmailVerified(false));
      yield put(resendEmailVerificationLoaderFunc(false));
    }
  } catch (error) {
    yield put(signupUserEmailVerified(true));
    yield put(resendEmailVerificationLoaderFunc(false));
  }
}

//  resend link for email verification watcher
export function* resendEmailVerificationLinkWatcher() {
  yield takeLatest(authType.RESEND_EMAIL_VERIFICATION_LINK, resendEmailVerificationLinkFunction);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(signInUserWatcher),
    fork(ssoLoginWatcher),
    fork(schoologyLaunchWatcher),
    fork(updatePasswordWatcher),
    fork(forgotPasswordWatcher),
    fork(signOutWatcher),
    fork(fetchPconfigDetailsWatcher),
    fork(loginFromECommerceSiteWatcher),
    fork(changePasswordWatcher),
    fork(fetchDropdownCityListWatcher),
    fork(fetchDropdownStateListWatcher),
    fork(fetchCityStateByZipWatcher),
    fork(resendEmailVerificationLinkWatcher),
  ]);
  // For fetch if no auth header set then get token from storage
  if (!axios.defaults.headers.common['Authorization'] && sessionStorage.getItem('Authorization') != null) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem('Authorization');
  }
  if (!axios.defaults.headers.common['supervisor'] && sessionStorage.getItem('supervisor') != null) {
    axios.defaults.headers.common['supervisor'] = sessionStorage.getItem('supervisor');
  }
}

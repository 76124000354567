import * as AdminType from '../../constants/ActionTypes';
import {all, fork, put, takeLatest} from 'redux-saga/effects';
import axios from 'util/Api';
import { saveDstrictAdminSchoolsData, saveDstrictAdminSchoolsSectionsData, saveDstictAdminSchoolsSectionsUsersData, saveDstictAdminSchoolsSectionsUsersReport, adminLoader } from '../../redux/actions/Admin';
import {errorHandler} from '../actions/Error';
import {exportToExcel} from '../../components/ExcelExport/ExcelExport';

// District Admin School data fetch worker
export function* fetchSchoolsData(payload) {
  try {
    yield put(adminLoader(true));
    const distictSchoolsData = yield axios.get(`/schools`);
    if (distictSchoolsData.status === 200) {
      yield put(saveDstrictAdminSchoolsData(distictSchoolsData.data));
      yield put(saveDstrictAdminSchoolsSectionsData([]));
      yield put(saveDstictAdminSchoolsSectionsUsersData([]));  
      yield put(adminLoader(false));
    } 
  } catch (error) {
    yield put(adminLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// District Admin Schools data fetch WATCHER
export function* fetchDistrictAdminSchoosDataWatcher() {
  yield takeLatest(AdminType.FETCH_DISTRICT_ADMIN_SCHOOLS_DATA, fetchSchoolsData);
}

//  District Admin School's Sections data fetch worker
export function* fetchSchoolsSectionsData({payload:{schoolId}}) {
  try {
    yield put(adminLoader(true));
    const schoolsSectionsData = yield axios.get(`/sections/${schoolId}`);
    if (schoolsSectionsData.status === 200) {
      console.log(schoolsSectionsData)
      yield put(saveDstrictAdminSchoolsSectionsData(schoolsSectionsData.data));

      yield put(adminLoader(false));
    }
    yield put(adminLoader(false));
  } catch (error) {
    yield put(adminLoader(false));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// // District Admin School's Sections data fetch WATCHER
export function* fetchDistrictAdminSectionDataWatcher() {
  yield takeLatest(AdminType.FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_DATA, fetchSchoolsSectionsData);
}

// District Admin School's Section's users data fetch worker
export function* fetchSchoolsSectionsUserData({payload:{sectionId}}) {
  try {
    yield put(adminLoader(true));
    const userDetails = yield axios.get(`/users/${sectionId}`);
    if (userDetails.status === 200) {
      yield put(saveDstictAdminSchoolsSectionsUsersData(userDetails.data));   
      yield put(adminLoader(false));
    }
    yield put(adminLoader(false));
  } catch (error) {
    yield put(adminLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// // District Admin School's Section's users data fetch WATCHER
export function* fetchDistrictAdminUserDataWatcher() {
  yield takeLatest(AdminType.FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_DATA, fetchSchoolsSectionsUserData);
}

 // District Admin School's Section's users Report Data fetch worker
export function* fetchSchoolsSectionsUsersReport({payload: {schoolId, clSectionId, userId}}) {
  try {
    yield put(adminLoader(true));
    const excelReportData = yield axios.get(`/report/${schoolId}/${clSectionId}/${userId}`);

    if (excelReportData.status === 200) {
      yield put(saveDstictAdminSchoolsSectionsUsersReport(excelReportData.data));   

      const fileName = 'Distrct Admin Schoools Report';

      exportToExcel(
        {
          sheet: {
            sheetName: fileName || 'Excel Export',
            rows: excelReportData.data,
          },
        },
        `${fileName || 'Excel_Export'}_${new Date().toLocaleDateString()}`,
      );
      yield put(adminLoader(false));
    }
    yield put(adminLoader(false));
  } catch (error) {
    yield put(adminLoader(false));
    // yield put(errorHandler(error));
    yield put(errorHandler({error: error, page: ''}));
  }
}

// District Admin School's Section's users Report Data fetch WATCHER
export function* fetchDistrictAdminSchoolsReportDataWatcher() {
  yield takeLatest(AdminType.FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_REPORT, fetchSchoolsSectionsUsersReport);
}
// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(fetchDistrictAdminSchoosDataWatcher),
    fork(fetchDistrictAdminSectionDataWatcher),
    fork(fetchDistrictAdminUserDataWatcher),
    fork(fetchDistrictAdminSchoolsReportDataWatcher),
  ]);
  
}

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const DRAWER_TYPE = 'DRAWER_TYPE';
export const FIXED_DRAWER = 'FIXED_DRAWER';
export const COLLAPSED_DRAWER = 'COLLAPSED_DRAWER';
export const MINI_DRAWER = 'MINI_DRAWER';
export const THEME_COLOR = 'THEME_COLOR';
export const DARK_THEME = 'DARK_THEME';
export const WINDOW_WIDTH = 'WINDOW_WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION';

export const CHANGE_NAVIGATION_STYLE = 'CHANGE_NAVIGATION_STYLE';
export const HORIZONTAL_NAVIGATION = 'HORIZONTAL_NAVIGATION';
export const VERTICAL_NAVIGATION = 'VERTICAL_NAVIGATION';

export const HORIZONTAL_MENU_POSITION = 'HORIZONTAL_MENU_POSITION';
export const ABOVE_THE_HEADER = 'ABOVE_THE_HEADER';
export const INSIDE_THE_HEADER = 'INSIDE_THE_HEADER';
export const BELOW_THE_HEADER = 'BELOW_THE_HEADER';

//Contact Module const
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';

// Auth const
const USERENTITY = '[USER]';
export const SIGNIN_USER = `${USERENTITY} SIGNIN_USER`;
export const SIGNOUT_USER = `${USERENTITY} SIGNOUT_USER`;
export const AUTO_SIGNIN = `${USERENTITY} AUTO_SIGNIN`;
export const SIGNIN_USER_SUCCESS = `${USERENTITY} SIGNIN_USER_SUCCESS`;
export const SIGNOUT_USER_SUCCESS = `${USERENTITY} SIGNOUT_USER_SUCCESS`;
export const FORGOT_PASSWORD_START = `${USERENTITY} FORGOT_PASSWORD_START`;
export const UPDATE_PASSWORD_LOADER = `${USERENTITY} UPDATE_PASSWORD_LOADER`;
export const UPDATE_PASSWORD = `${USERENTITY} UPDATE_PASSWORD`;
export const FLUSH_APP_DETAILS = `${USERENTITY} FLUSH_APP_DETAILS`;
export const FETCH_PCONFIG = `${USERENTITY} FETCH_PCONFIG`;
export const SET_CONFIG_LOADER = `${USERENTITY} SET_CONFIG_LOADER`;
export const SET_PCONFIG = `${USERENTITY} SET_PCONFIG`;
export const SET_SUPERVISOR_ID = `${USERENTITY} SET_SUPERVISOR_ID`;
export const SET_EMPLOYEE_ID = `${USERENTITY} SET_EMPLOYEE_ID`;
export const LOGIN_FROM_E_COMMERCE_SITE = `${USERENTITY} LOGIN_FROM_E_COMMERCE_SITE`;
export const RESEND_EMAIL_VERIFICATION_LINK = `${USERENTITY} RESEND_EMAIL_VERIFICATION_LINK`;
export const VERIFY_USER_EMAIL_EXIST = `${USERENTITY} VERIFY_USER_EMAIL_EXIST`;
export const RESEND_EMAIL_VERIFICATION_LINK_LOADER = `${USERENTITY} RESEND_EMAIL_VERIFICATION_LINK_LOADER`;
export const CHANGE_PASSWARD_UPDATE = `${USERENTITY} CHANGE_PASSWARD_UPDATE`;
export const CHANGE_PASSWORD_FROM_EMAIL_LINK = `${USERENTITY} CHANGE_PASSWORD_FROM_EMAIL_LINK`;
export const SET_UPDATE_PASSWORD_TOKEN = `${USERENTITY} SET_UPDATE_PASSWORD_TOKEN`;
export const UPDATE_PASSWORD_COMPLETED = `${USERENTITY} UPDATE_PASSWORD_COMPLETED`;
export const SAVE_SSO_LOGIN_DETAILS = `${USERENTITY} SAVE_SSO_LOGIN_DETAILS`;
export const SCHOOLOGY_APP_COURSES_LIST = `${USERENTITY} SCHOOLOGY_APP_COURSES_LIST`;
export const SAVE_SCHOOLOGY_SSO_LOGIN_DETAILS = `${USERENTITY} SAVE_SCHOOLOGY_SSO_LOGIN_DETAILS`;
export const CLEAR_SCHOOLOGY_SECTION_ID = `${USERENTITY} CLEAR_SCHOOLOGY_SECTION_ID`;
export const FETCH_INITIAL_SCHOOLOGY_DATA_FLAG = `${USERENTITY} FETCH_INITIAL_SCHOOLOGY_DATA_FLAG`;
export const IS_SCHOOLOGY_APP_FLAG = `${USERENTITY} IS_SCHOOLOGY_APP_FLAG`;

export const INIT_URL = '[APP] INIT_URL';
export const USER_DATA = 'USER_DATA';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const FETCH_DROPDOWN_STATE_LIST = `${USERENTITY} FETCH_DROPDOWN_STATE_LIST`;
export const DROPDOWN_STATE_LIST_SUCCESS = `${USERENTITY} DROPDOWN_STATE_LIST_SUCCESS`;
export const FETCH_DROPDOWN_CITY_LIST = `${USERENTITY} FETCH_DROPDOWN_CITY_LIST`;
export const DROPDOWN_CITY_LIST_SUCCESS = `${USERENTITY} DROPDOWN_CITY_LIST_SUCCESS`;
export const FETCH_CITY_STATE_BY_ZIP = `${USERENTITY} FETCH_CITY_STATE_BY_ZIP`;
export const CITY_STATE_DATA_BY_ZIP_SUCCESS = `${USERENTITY} CITY_STATE_DATA_BY_ZIP_SUCCESS`;
export const FETCH_TDN_SSO_LOGIN_DETAILS = `${USERENTITY} FETCH_TDN_SSO_LOGIN_DETAILS`;
export const FETCH_TDN_SCHOOLOGY_LAUNCH_DETAILS = `${USERENTITY} FETCH_TDN_SCHOOLOGY_LAUNCH_DETAILS`;
export const FETCH_TDN_SCHOOLOGY_CLICK_LAUNCH_DETAILS = `${USERENTITY} FETCH_TDN_SCHOOLOGY_CLICK_LAUNCH_DETAILS`;

// App Loader
const UIENTITY = '[UI]';
export const ON_SHOW_LOADER = `${UIENTITY} ON_SHOW_LOADER`;
export const ON_HIDE_LOADER = `${UIENTITY} ON_HIDE_LOADER`;
export const SHOW_MESSAGE = `${UIENTITY} SHOW_MESSAGE`;
export const HIDE_MESSAGE = `${UIENTITY} HIDE_MESSAGE`;
export const SHOW_HIDE_IDLE_MODAL = `${UIENTITY} SHOW_HIDE_IDLE_MODAL`;

// Error Hnadling
const ERRORENTITY = '[ERROR]';
export const ERROR_DETAILS = `${ERRORENTITY} ERROR_DETAILS`;
export const ADD_ERRORR = `${ERRORENTITY} ADD_ERRORR`;
export const CLEAR_ERROR = `${ERRORENTITY} CLEAR_ERROR`;

//Profile data page
const PROFILEENTITY = '[PROFILE]';

export const FETCH_PROFILE_DATA = `${PROFILEENTITY} FETCH_PROFILE_DATA`;
export const FETCH_PROFILE_DATA_SUCCESS = `${PROFILEENTITY} FETCH_PROFILE_DATA_SUCCESS`;
export const FETCH_PROFILE_DATA_FAIL = `${PROFILEENTITY} FETCH_PROFILE_DATA_FAIL`;
export const PROFILE_SHOW_LOADER = `${PROFILEENTITY} PROFILE_SHOW_LOADER`;
export const PROFILE_HIDE_LOADER = `${PROFILEENTITY} PROFILE_HIDE_LOADER`;
export const FETCH_AK_PROFILE_DATA = `${PROFILEENTITY} FETCH_AK_PROFILE_DATA`;
export const SAVE_AK_PROFILE_DATA = `${PROFILEENTITY} SAVE_AK_PROFILE_DATA`;
export const UPDATE_AK_PROFILE_DATA = `${PROFILEENTITY} UPDATE_AK_PROFILE_DATA`;
export const SET_HAS_EMAIL_VERIFIED_FLAG = `${PROFILEENTITY} SET_HAS_EMAIL_VERIFIED_FLAG`;

// AMERIKOOLER COURSE
const AMERIKOOLERENTITY = '[AMERIKOOLER]';
export const OPEN_CLOSE_SHOW_DETAILS = `${AMERIKOOLERENTITY} OPEN_CLOSE_SHOW_DETAILS`;
export const AK_FETCH_COURSE_DATA_LOADER = `${AMERIKOOLERENTITY} AK_FETCH_COURSE_DATA_LOADER`;
export const AK_COURSE_LOADER = `${AMERIKOOLERENTITY} AK_COURSE_LOADER`;
export const AK_FETCH_COURSE_DATA = `${AMERIKOOLERENTITY} AK_FETCH_COURSE_DATA`;
export const AK_SAVE_COURSE_DATA = `${AMERIKOOLERENTITY} AK_SAVE_COURSE_DATA`;
export const AK_SAVE_CURRENT_UNIT = `${AMERIKOOLERENTITY} AK_SAVE_CURRENT_UNIT`;
export const AK_SAVE_CURRENT_LESSON = `${AMERIKOOLERENTITY} AK_SAVE_CURRENT_LESSON`;
export const AK_SAVE_CURRENT_RESOURCE = `${AMERIKOOLERENTITY} AK_SAVE_CURRENT_RESOURCE`;
export const AK_NEXT_STEP = `${AMERIKOOLERENTITY} AK_NEXT_STEP`;
export const AK_NEXT_STEP_LOADER = `${AMERIKOOLERENTITY} AK_NEXT_STEP_LOADER`;
export const AK_PREVIOUS_STEP = `${AMERIKOOLERENTITY} AK_PREVIOUS_STEP`;
export const AK_PREVIOUS_STEP_LOADER = `${AMERIKOOLERENTITY} AK_PREVIOUS_STEP_LOADER`;
export const AK_SAVE_HMI_DATA = `${AMERIKOOLERENTITY} AK_SAVE_HMI_DATA`;
export const AK_SAVE_HMI_STEPS = `${AMERIKOOLERENTITY} AK_SAVE_HMI_STEPS`;
export const AK_SAVE_VR_DATA = `${AMERIKOOLERENTITY} AK_SAVE_VR_DATA`;
export const AK_SAVE_PDF_DATA = `${AMERIKOOLERENTITY} AK_SAVE_PDF_DATA`;
export const AK_SAVE_VIDEO_DATA = `${AMERIKOOLERENTITY} AK_SAVE_VIDEO_DATA`;
export const AK_SAVE_CONTENT_HTML_DATA = `${AMERIKOOLERENTITY} AK_SAVE_CONTENT_HTML_DATA`;
export const AK_SAVE_SCORM_DATA = `${AMERIKOOLERENTITY} AK_SAVE_SCORM_DATA`;
export const AK_SAVE_SCORM_CMI_DATA = `${AMERIKOOLERENTITY} AK_SAVE_SCORM_CMI_DATA`;
export const AK_SAVE_FIRST_AND_LAST_RESOURCE_ID = `${AMERIKOOLERENTITY} AK_SAVE_FIRST_AND_LAST_RESOURCE_ID`;
export const AK_UPDATE_CURRENT_UNIT = `${AMERIKOOLERENTITY} AK_UPDATE_CURRENT_UNIT`;
export const AK_LOCK_COURSE_BUTTON = `${AMERIKOOLERENTITY} AK_LOCK_COURSE_BUTTON`;
export const AK_VR_FULL_SCREEN = `${AMERIKOOLERENTITY} AK_VR_FULL_SCREEN`;
export const AK_HMI_SESSION_END = `${AMERIKOOLERENTITY} AK_HMI_SESSION_END`;
export const UPDATE_USER_INFO_TIME_INTERVAL = `${AMERIKOOLERENTITY} UPDATE_USER_INFO_TIME_INTERVAL`;
export const AK_FETCH_CERTIFICATE = `${AMERIKOOLERENTITY} AK_FETCH_CERTIFICATE`;
export const AK_CERTIFICATE_LOADER = `${AMERIKOOLERENTITY} AK_CERTIFICATE_LOADER`;
export const UPDATE_USER_INFO_TIME = `${AMERIKOOLERENTITY} UPDATE_USER_INFO_TIME`;
export const UPDATE_USER_INFO_TIME_LOADER = `${AMERIKOOLERENTITY} UPDATE_USER_INFO_TIME_LOADER`;
export const UPDATE_VIDEO_TIME_AFTER_PROGRESS_API_CALLED = `${AMERIKOOLERENTITY} UPDATE_VIDEO_TIME_AFTER_PROGRESS_API_CALLED`;
export const AK_FETCH_VR_RESOURCE_COMPLETE_DATA = `${AMERIKOOLERENTITY} AK_FETCH_VR_RESOURCE_COMPLETE_DATA`;
export const AK_FETCH_VIDEO_PLAYED_SECONDS = `${AMERIKOOLERENTITY} AK_FETCH_VIDEO_PLAYED_SECONDS`;
export const AK_SAVE_VIDEO_PLAYED_SECONDS = `${AMERIKOOLERENTITY} AK_SAVE_VIDEO_PLAYED_SECONDS`;
export const AK_SAVE_UPDATED_VIDEO_PLAYED_SECONDS = `${AMERIKOOLERENTITY} AK_SAVE_UPDATED_VIDEO_PLAYED_SECONDS`;
export const AK_UPDATE_VIDEO_PLAYED_SECONDS = `${AMERIKOOLERENTITY} AK_UPDATE_VIDEO_PLAYED_SECONDS`;
export const AK_SHOW_HIDE_CERTIFICATE_MODAL = `${AMERIKOOLERENTITY} AK_SHOW_HIDE_CERTIFICATE_MODAL`;
export const SAVE_SINGLE_SCORM_RESOURCE_PROGRESS = `${AMERIKOOLERENTITY} SAVE_SINGLE_SCORM_RESOURCE_PROGRESS`;
export const AK_SINGLE_SCORM_RESOURCE_CERTIFICATE_BUTTON_ENABLE = `${AMERIKOOLERENTITY} AK_SINGLE_SCORM_RESOURCE_CERTIFICATE_BUTTON_ENABLE`;

// DASHBOARD
const DASHBOARDENTITY = '[DASHBOARD]';
export const FETCH_DASHBOARD_DATA = `${DASHBOARDENTITY} FETCH_DASHBOARD_DATA`;
export const SAVE_DASHBOARD_DATA = `${DASHBOARDENTITY} SAVE_DASHBOARD_DATA`;
export const DASHBOARD_LOADER = `${DASHBOARDENTITY} DASHBOARD_LOADER`;
export const FETCH_DASHBOARD_COURSES_DATA = `${DASHBOARDENTITY} FETCH_DASHBOARD_COURSES_DATA`;
export const SAVE_DASHBOARD_COURSES_DATA = `${DASHBOARDENTITY} SAVE_DASHBOARD_COURSES_DATA`;
export const SET_CURRENT_SELECTION_Data = `${DASHBOARDENTITY} SET_CURRENT_SELECTION_Data`;
export const FETCH_SCHOOLOGY_ADD_COURSE_DETAILS = `${DASHBOARDENTITY} FETCH_SCHOOLOGY_ADD_COURSE_DETAILS`;

// ADMIN
const ADMINENTITY = '[ADMIN]';
export const FETCH_DISTRICT_ADMIN_SCHOOLS_DATA = `${ADMINENTITY} FETCH_DISTRICT_ADMIN_SCHOOLS_DATA`;
export const SAVE_DISTRICT_ADMIN_SCHOOLS_DATA = `${ADMINENTITY} SAVE_DISTRICT_ADMIN_SCHOOLS_DATA`;
export const ADMIN_LOADER = `${ADMINENTITY} ADMIN_LOADER`;
export const FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_DATA = `${ADMINENTITY} FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_DATA`;
export const SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_DATA = `${ADMINENTITY} SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_DATA`;
export const FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_DATA = `${ADMINENTITY} FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_DATA`;
export const SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_DATA = `${ADMINENTITY} SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_DATA`;
export const FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_REPORT = `${ADMINENTITY} FETCH_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_REPORT`;
export const SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_REPORT = `${ADMINENTITY} SAVE_DISTRICT_ADMIN_SCHOOLS_SECTIONS_USERS_REPORT`;


const TDNENTITY = '[TDN]';
export const TDN_FETCH_COURSE_DATA_LOADER = `${TDNENTITY} TDN_FETCH_COURSE_DATA_LOADER`;
export const TDN_COURSE_LOADER = `${TDNENTITY} TDN_COURSE_LOADER`;
export const TDN_FETCH_COURSE_DATA = `${TDNENTITY} TDN_FETCH_COURSE_DATA`;
export const TDN_FETCH_RESOURCE_DATA = `${TDNENTITY} TDN_FETCH_RESOURCE_DATA`;
export const TDN_LAUNCH_DATA = `${TDNENTITY} TDN_LAUNCH_DATA`;
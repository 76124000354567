import * as types from '../../constants/ActionTypes';

const INIT_STATE = {
  submitLoader: false,
  profileData: '',
  amerikoolerProfileData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.PROFILE_SHOW_LOADER: {
      return {
        ...state,
        submitLoader: true,
      };
    }
    case types.PROFILE_HIDE_LOADER: {
      return {
        ...state,
        submitLoader: false,
      };
    }

    case types.FETCH_PROFILE_DATA_SUCCESS: {
      return {
        ...state,
        profileData: action.payload,
      };
    }

    case types.SAVE_AK_PROFILE_DATA: {
      return {
        ...state,
        amerikoolerProfileData: action.payload,
      };
    }

    case types.SET_HAS_EMAIL_VERIFIED_FLAG: {
      return {
        ...state,
        amerikoolerProfileData : {
          ...state.amerikoolerProfileData,
          hasVerifiedEmail : action.payload,
        }
      };
    }

    default:
      return state;
  }
};

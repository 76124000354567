export const HMI = 'HMI';
export const VR = 'VR';
export const NEXT = 'next';
export const PREVIOUS = 'previous';
export const PUBLISH = 'Publish';
export const PENDING = 'Pending';
export const TESTS = 'Tests';
export const OK = 'OK';
export const FILE = 'File';
export const VR_STEPS_TYPE_CONTENT = 'content';
export const VR_STEPS_TYPE_RESOURSE = 'resource';
export const SUPERVISOR = 5;
export const INSTRUCTOR = 2;
export const STUDENT = 3;
export const ADMIN = 6;
export const HTML_EM = '--';
export const COMPLETED = 'COMPLETED';
export const FAILED = 'FAILED';
export const LAUNCH = 'launch';
export const DEEP_LINKING = 'deep-linking';
export const COURSE_TYPE_SCORM = 'Scorm';
export const CLIENT_SANOFI = 'sanofi';
export const CLIENT_AMERIKOOLER = 'amerikooler';
export const CONTENT_HTML = 'Content HTML';
export const VIDEO = 'VIDEO';
export const PDF = 'PDF';
export const WEBSITE = 'Website';

//learners Detals
export const learnerDetails = [
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Rahul',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1001',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
  {
    name: 'Singhs',
    email: 'singh@gmail.com',
    state: 'bhopal',
    created: '01/02/2021',
    last_login: '10/10/2021',
    course_status: 'Certified',
    time_spent: '03:20:00',
    order_id: '1000',
    amount: '$100',
  },
];

// Us State Array
export const stateName = [
  {
    value: 'Select State',
    label: '',
  },
  {
    value: 'Alabama',
    label: 'Alabama',
  },
  {
    value: 'Alaska',
    label: 'Alaska',
  },
  {
    value: 'Arizona',
    label: 'Arizona',
  },
  {
    value: 'Arkansas',
    label: 'Arkansas',
  },
  {
    value: 'California',
    label: 'California',
  },
  {
    value: 'Colorado',
    label: 'Colorado',
  },
  {
    value: 'Connecticut',
    label: 'Connecticut',
  },
  {
    value: 'Delaware',
    label: 'Delaware',
  },
  {
    value: 'Florida',
    label: 'Florida',
  },
  {
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    value: 'Hawaii',
    label: 'Hawaii',
  },
  {
    value: 'Idaho',
    label: 'Idaho',
  },
  {
    value: 'Illinois',
    label: 'Illinois',
  },
  {
    value: 'Indiana',
    label: 'Indiana',
  },
  {
    value: 'Iowa',
    label: 'Iowa',
  },
  {
    value: 'Kansas',
    label: 'Kansas',
  },
  {
    value: 'Kentucky',
    label: 'Kentucky',
  },
  {
    value: 'Louisiana',
    label: 'Louisiana',
  },
  {
    value: 'Maine',
    label: 'Maine',
  },
  {
    value: 'Maryland',
    label: 'Maryland',
  },
  {
    value: 'Massachusetts',
    label: 'Massachusetts',
  },
  {
    value: 'Michigan',
    label: 'Michigan',
  },
  {
    value: 'Minnesota',
    label: 'Minnesota',
  },
  {
    value: 'Mississippi',
    label: 'Mississippi',
  },
  {
    value: 'Missouri',
    label: 'Missouri',
  },
  {
    value: 'Montana',
    label: 'Montana',
  },
  {
    value: 'Nebraska',
    label: 'Nebraska',
  },

  {
    value: 'Nevada',
    label: 'Nevada',
  },
  {
    value: 'New Hampshire',
    label: 'New Hampshire',
  },
  {
    value: 'New Jersey',
    label: 'New Jersey',
  },
  {
    value: 'New Mexico',
    label: 'New Mexico',
  },
  {
    value: 'New York',
    label: 'New York',
  },
  {
    value: 'North Carolina',
    label: 'North Carolina',
  },
  {
    value: 'North Dakota',
    label: 'North Dakota',
  },
  {
    value: 'Ohio',
    label: 'Ohio',
  },
  {
    value: 'Oklahoma',
    label: 'Oklahoma',
  },
  {
    value: 'Oregon',
    label: 'Oregon',
  },
  {
    value: 'Pennsylvania',
    label: 'Pennsylvania',
  },
  {
    value: 'Rhode Island',
    label: 'Rhode Island',
  },
  {
    value: 'South Carolina',
    label: 'South Carolina',
  },
  {
    value: 'South Dakota',
    label: 'South Dakota',
  },
  {
    value: 'Tennessee',
    label: 'Tennessee',
  },
  {
    value: 'Texas',
    label: 'Texas',
  },
  {
    value: 'Utah',
    label: 'Utah',
  },
  {
    value: 'Vermont',
    label: 'Vermont',
  },
  {
    value: 'Virginia',
    label: 'Virginia',
  },
  {
    value: 'Washington',
    label: 'Washington',
  },
  {
    value: 'Washington DC',
    label: 'Washington DC',
  },
  {
    value: 'West Virginia',
    label: 'West Virginia',
  },
  {
    value: 'Wisconsin',
    label: 'Wisconsin',
  },
  {
    value: 'Wyoming',
    label: 'Wyoming',
  },
];

export const amerikoolerCourseData = [
  {
    course_section: 1,
    course_name: 'Amerikooler Installation Course',
    course_short_sub_name: 'AIS',
    course_id: 1,
    instructor_id: 2,
    course_desc: '',
    order: null,
    percent_completed: '16.67',
    course_url: null,
    course_type: 'Normal',
    units: [
      {
        unit_id: 1,
        name: 'Modules',
        unit_sub_title: 'Modules',
        order: 1,
        is_complete: true,
        is_ctr_resource: false,
        no_of_lessons: 0,
        lessons: [
          {
            is_complete: true,
            lesson_id: 36,
            name: 'Module 1',
            order: 1,
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
            resources: [
              {
                resource_id: 65,
                name: 'Sub module 1-1',
                order: 2,
                last_visited_resource: false,
                resource_type: 'File',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish',
                // url: 'https://lmsv2cdn.higherl.com/cdl/instructor-data/3121/mp4/Alo815e4a8.mp4',
                url: 'http://techslides.com/demos/sample-videos/small.mp4',
              },
              {
                resource_id: 33,
                name: 'Sub module 1-2',
                order: 2,
                last_visited_resource: true,
                resource_type: 'VR',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish',
                grade: '4',
                total_points: '50.00',
                url: 'https://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf',
              },
              {
                resource_id: 2,
                name: 'Sub module 1-3',
                order: 3,
                last_visited_resource: false,
                resource_type: 'HMI',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish', //vr
              },
              {
                resource_id: 3,
                name: 'Sub module 1-4',
                order: 1,
                last_visited_resource: false,
                resource_type: 'HMI',
                screen_type: 'HMI_SANDBOX',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish',
              },
            ],
          },
          {
            lesson_id: 37,
            name: 'Module 2',
            order: 2,
            is_complete: true,
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
            resources: [
              {
                resource_id: 372,
                name: 'Sub module 2-1',
                order: 1,
                last_visited_resource: false,
                resource_type: 'PDF',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish',
                grade: '4',
                total_points: '50.00',
                url: 'http://www.africau.edu/images/default/sample.pdf',
              },
              {
                resource_id: 373,
                name: 'Sub module 2-2',
                order: 1,
                last_visited_resource: false,
                resource_type: 'PDF',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish',
                grade: '4',
                total_points: '50.00',
                url: 'https://www.orimi.com/pdf-test.pdf',
              },
              {
                resource_id: 4,
                name: 'Sub module 2-3',
                order: 1,
                last_visited_resource: false,
                resource_type: 'HMI',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish',
                grade: '4',
                total_points: '50.00',
              },
            ],
          },
          {
            lesson_id: 38,
            name: 'Module 3',
            order: 3,
            is_complete: true,
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
            resources: [
              {
                resource_id: 377,
                name: 'Sub module 3-1',
                order: 2,
                last_visited_resource: true,
                resource_type: 'PDF',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish',
                grade: '4',
                total_points: '50.00',
                url: 'https://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf',
              },
            ],
          },
          {
            lesson_id: 39,
            name: 'Module 4',
            order: 4,
            is_complete: true,
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing',
            resources: [
              {
                resource_id: 2,
                name: 'Sub module 4-1',
                order: 1,
                last_visited_resource: false,
                resource_type: 'HMI',
                screen_type: 'HMI_SANDBOX',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish',
              },
              {
                resource_id: 101,
                name: 'Sub module 4-2',
                order: 2,
                last_visited_resource: false,
                resource_type: 'VIDEO',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish',
                url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
              },
              {
                resource_id: 253,
                name: 'Sub module 4-3',
                order: 3,
                last_visited_resource: false,
                resource_type: 'HMI',
                is_ctr_resource: '1',
                is_complete: true,
                content_status: 'Publish', //vr
              },
            ],
          },
        ],
      },
    ],
  },
];

// export const CoursesTableData = [
//   {
//     course_id: 1,
//     enrolled_name: 'Antigen',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 2,
//     enrolled_name: 'FFIP',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 3,
//     enrolled_name: 'HSE',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 4,
//     enrolled_name: 'Course 4',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 5,
//     enrolled_name: 'Course 5',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 6,
//     enrolled_name: 'Course 6',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 7,
//     enrolled_name: 'Course 7',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 8,
//     enrolled_name: 'Course 8',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 9,
//     enrolled_name: 'Course 9',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 10,
//     enrolled_name: 'Course 10',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 11,
//     enrolled_name: 'Course 11',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 12,
//     enrolled_name: 'Course 12',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 13,
//     enrolled_name: 'Course 13',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 14,
//     enrolled_name: 'Course 14',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 15,
//     enrolled_name: 'Course 15',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 16,
//     enrolled_name: 'Course 16',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 17,
//     enrolled_name: 'Course 17',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 18,
//     enrolled_name: 'Course 18',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 19,
//     enrolled_name: 'Course 19',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 20,
//     enrolled_name: 'Course 20',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
//   {
//     course_id: 21,
//     enrolled_name: 'Course 21',
//     enrolled_no: 100,
//     completed: 60,
//     in_progress: 20,
//     to_start: 10,
//     resources: 20,
//     quizzes: '',
//     logins: 50,
//     comments: 10,
//   },
// ];

export const studentsTableData = [
  {
    student_id: 1,
    student_name: 'Student 1',
    content_completed: 100,
    total_content: 6,
    completed_content: 6,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [],
  },
  {
    student_id: 2,
    student_name: 'Student 2',
    content_completed: 65,
    total_content: 6,
    completed_content: 3,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 3,
    student_name: 'Student 3',
    content_completed: 85,
    total_content: 6,
    completed_content: 7,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 4,
    student_name: 'Student 4',
    content_completed: 60,
    total_content: 6,
    completed_content: 4,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 5,
    student_name: 'Student 5',
    content_completed: 55,
    total_content: 6,
    completed_content: 4,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 6,
    student_name: 'Student 6',
    content_completed: 45,
    total_content: 6,
    completed_content: 3,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 7,
    student_name: 'Student 7',
    content_completed: 10,
    total_content: 6,
    completed_content: 1,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 8,
    student_name: 'Student 8',
    content_completed: 35,
    total_content: 6,
    completed_content: 3,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 9,
    student_name: 'Student 9',
    content_completed: 95,
    total_content: 6,
    completed_content: 5,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 10,
    student_name: 'Student 10',
    content_completed: 70,
    total_content: 6,
    completed_content: 4,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
];

export const studentsTableData1 = [
  {
    student_id: 1,
    student_name: 'Student 101',
    content_completed: 100,
    total_content: 6,
    completed_content: 6,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 95,
        phy: 65,
        che: 50,
      },
      {
        phy: 90,
        math: 80,
        che: 100,
      },
    ],
  },
  {
    student_id: 2,
    student_name: 'Student 201',
    content_completed: 65,
    total_content: 6,
    completed_content: 3,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 3,
    student_name: 'Student 301',
    content_completed: 85,
    total_content: 6,
    completed_content: 7,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
  {
    student_id: 4,
    student_name: 'Student 401',
    content_completed: 60,
    total_content: 6,
    completed_content: 4,
    logins: [
      {month: 'January', no_of_times: 60},
      {month: 'Feb', no_of_times: 50},
      {month: 'March', no_of_times: 80},
      {month: 'April', no_of_times: 30},
      {month: 'May', no_of_times: 30},
      {month: 'June', no_of_times: 30},
      {month: 'July', no_of_times: 30},
      {month: 'August', no_of_times: 30},
      {month: 'September', no_of_times: 30},
      {month: 'October', no_of_times: 30},
      {month: 'November', no_of_times: 30},
      {month: 'December', no_of_times: 30},
    ],
    grades: [
      {
        math: 85,
        phy: 75,
        che: 20,
      },
      {
        phy: 100,
        math: 85,
        che: 50,
      },
    ],
  },
];

export const loginActivity = [
  {login_date: '16/02/2020', logins_cnt: 160},
  {login_date: '16/02/2020', logins_cnt: 50},
  {login_date: '16/02/2020', logins_cnt: 30},
  {login_date: '16/02/2020', logins_cnt: 80},
  {login_date: '16/02/2020', logins_cnt: 80},
  {login_date: '16/02/2020', logins_cnt: 20},
  {login_date: '16/02/2020', logins_cnt: 40},
  {login_date: '16/02/2020', logins_cnt: 50},
  {login_date: '16/02/2020', logins_cnt: 80},
  {login_date: '16/02/2020', logins_cnt: 80},
  {login_date: '16/02/2020', logins_cnt: 30},
  {login_date: '16/02/2020', logins_cnt: 45},
  {login_date: '16/02/2020', logins_cnt: 60},
  {login_date: '16/02/2020', logins_cnt: 36},
  {login_date: '16/02/2020', logins_cnt: 76},
  {login_date: '16/02/2020', logins_cnt: 65},
  {login_date: '16/02/2020', logins_cnt: 55},
  {login_date: '16/02/2020', logins_cnt: 45},
  {login_date: '16/02/2020', logins_cnt: 50},
  {login_date: '16/02/2020', logins_cnt: 50},
];

/* export const recent_activity = [
  {
    type: 'assignment',
    name: 'John Smith',
    message: 'Assginment 1 Submiited',
    taskTime: '1 hour ago',
    time: '10:25am',
  },
  {
    type: 'quiz',
    name: 'Jane Smith',
    message: 'Assginment 2 Submiited',
    taskTime: '3 hour ago',
    time: '14:28pm',
  },
  {
    type: 'comment',
    name: 'John Nash',
    message: 'Quiz 1 Submiited',
    taskTime: '1 day ago',
    time: '11:32am',
  },
  {
    type: 'assignment',
    name: 'Jane Nash',
    message: 'Replied to Comment',
    taskTime: '1 day ago',
    time: '16:21pm',
  },
  {
    type: 'quiz',
    name: 'John Smith',
    message: 'Assginment 1 Submiited',
    taskTime: '1 hour ago',
    time: '10:25am',
  },
  {
    type: 'comment',
    name: 'John Smith',
    message: 'Assginment 1 Submiited',
    taskTime: '1 hour ago',
    time: '10:25am',
  },
  {
    type: 'assignment',
    name: 'John Smith',
    message: 'Assginment 1 Submiited',
    taskTime: '1 hour ago',
    time: '10:25am',
  },
];
 */
export const CourseDashboardCardData = [
  {name: 'Completed', value: 400, color: '#F4F7FC'},
  {name: 'In Progress', value: 300, color: '#525CA3'},
  {name: 'To Start', value: 300, color: '#E4E6F1'},
];

export const dashboardData = {
  course_data: [
    {
      course_name: 'Antigen',
      course_desc: '',
      nummer_of_student: 60,
      graph_data: [
        {name: 'Completed', value: 400},
        {name: 'In Progress', value: 300},
        {name: 'To Start', value: 300},
      ],
    },
    {
      course_name: 'FFIP',
      course_desc: 'Formulation, Filling, Inspection, and Packaging',
      nummer_of_student: 50,
      graph_data: [
        {name: 'Completed', value: 400},
        {name: 'In Progress', value: 300},
        {name: 'To Start', value: 300},
      ],
    },
    {
      course_name: 'HSE',
      course_desc: '',
      nummer_of_student: 50,
      graph_data: [
        {name: 'Completed', value: 400},
        {name: 'In Progress', value: 300},
        {name: 'To Start', value: 300},
      ],
    },
  ],
  recent_activity: [
    {
      type: 'assignment',
      name: 'John Smith',
      message: 'Assginment 1 Submiited',
      taskTime: '1 hour ago',
      time: '10:25am',
    },
    {
      type: 'quiz',
      name: 'Jane Smith',
      message: 'Assginment 2 Submiited',
      taskTime: '3 hour ago',
      time: '14:28pm',
    },
    {
      type: 'comment',
      name: 'John Nash',
      message: 'Quiz 1 Submiited',
      taskTime: '1 day ago',
      time: '11:32am',
    },
    {
      type: 'assignment',
      name: 'Jane Nash',
      message: 'Replied to Comment',
      taskTime: '1 day ago',
      time: '16:21pm',
    },
    {
      type: 'quiz',
      name: 'John Smith',
      message: 'Assginment 1 Submiited',
      taskTime: '1 hour ago',
      time: '10:25am',
    },
    {
      type: 'comment',
      name: 'John Smith',
      message: 'Assginment 1 Submiited',
      taskTime: '1 hour ago',
      time: '10:25am',
    },
    {
      type: 'assignment',
      name: 'John Smith',
      message: 'Assginment 1 Submiited',
      taskTime: '1 hour ago',
      time: '10:25am',
    },
  ],
  loginActivity: [
    {date: '16/02/2020', users: 160},
    {date: '16/02/2020', users: 50},
    {date: '16/02/2020', users: 30},
    {date: '16/02/2020', users: 80},
    {date: '16/02/2020', users: 80},
    {date: '16/02/2020', users: 20},
    {date: '16/02/2020', users: 40},
    {date: '16/02/2020', users: 50},
    {date: '16/02/2020', users: 80},
    {date: '16/02/2020', users: 80},
    {date: '16/02/2020', users: 30},
    {date: '16/02/2020', users: 45},
    {date: '16/02/2020', users: 60},
    {date: '16/02/2020', users: 36},
    {date: '16/02/2020', users: 76},
    {date: '16/02/2020', users: 65},
    {date: '16/02/2020', users: 55},
    {date: '16/02/2020', users: 45},
    {date: '16/02/2020', users: 50},
    {date: '16/02/2020', users: 50},
  ],
};
// const COMPLETED = 'Completed';
// const IN_PROGRESS = 'In Progress';
// const TO_START = 'To Start';

// export const CourseDashboardCardDataColor = {
//   'Completed ': '#F4F7FC',
//   'In Progress': '#525CA3',
//   'To Start': '#E4E6F1',
// };
export const recent_activity = [
  {
    user_id: 439,
    user_type_id: 3,
    first_name: 'E1234364',
    last_name: 'E1234364',
    email: 'e1234364@fakemail.com',
    action_type: 'view_course',
    action_datetime: '2021-01-07 02:43:11',
    time_diff_string: '1 day after',
    action_time: '02:43 AM',
  },
];

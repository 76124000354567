import * as types from '../../constants/ActionTypes';
const INIT_STATE = {
  loader: false,
  initURL: '',
  alertMessage: '',
  showMessage: false,
  authUser: sessionStorage.getItem('email_id'),
  userRole: null,
  userId: '',
  signinStatus: false,
  session_id: null,
  updatPasswordLoader: false,
  passwordTokenDetails: null,
  clientConfigration: {
    serverConfig: null,
  },
  employeeId: null,
  name: '',
  supervisorId: null,
  configLoader: false,
  dropDownStateList: [],
  dropDownCityList: [],
  cityStateName: null,
  emailVerifiedStatus: false,
  resendEmailVerificationLoader: false,
  changePasswordUpdate: false,
  ssoLoginDetails: null,
  schoologyAppCourseListFlag: false,
  saveSchoologyAppDetails: null,
  isSchoologyAPP: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        userId: null,
        initURL: '',
        loader: false,
        signinStatus: false,
        supervisorId: null,
      };
    }

    case types.SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        signinStatus: true,
        loader: false,
      };
    }

    case types.SET_SUPERVISOR_ID: {
      return {
        ...state,
        ...action.payload,
        supervisorId: action.payload.userId,
      };
    }

    case types.SET_EMPLOYEE_ID: {
      return {
        ...state,
        ...action.payload,
        employeeId: action.payload.employeeId,
        name: action.payload.name,
      };
    }

    case types.USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case types.CHANGE_PASSWARD_UPDATE: {
      return {
        ...state,
        changePasswordUpdate: action.payload,
      };
    }

    case types.RESEND_EMAIL_VERIFICATION_LINK_LOADER: {
      return {
        ...state,
        resendEmailVerificationLoader: action.payload,
      };
    }

    case types.ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }

    case types.ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case types.SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case types.SET_CONFIG_LOADER: {
      return {
        ...state,
        configLoader: action.payload,
      };
    }
    case types.SET_PCONFIG: {
      return {
        ...state,
        clientConfigration: {
          ...state.clientConfigration,
          serverConfig: action.payload,
        },
      };
    }
    case types.HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      };
    }
    case types.UPDATE_PASSWORD_LOADER: {
      return {
        ...state,
        updatPasswordLoader: action.payload,
      };
    }
    case types.DROPDOWN_STATE_LIST_SUCCESS: {
      return {
        ...state,
        dropDownStateList: action.payload,
      };
    }
    case types.DROPDOWN_CITY_LIST_SUCCESS: {
      return {
        ...state,
        dropDownCityList: action.payload,
      };
    }
    case types.CITY_STATE_DATA_BY_ZIP_SUCCESS: {
      return {
        ...state,
        cityStateName: action.payload,
      };
    }
    case types.VERIFY_USER_EMAIL_EXIST: {
      return {
        ...state,
        emailVerifiedStatus: action.payload,
      };
    }
    case types.SAVE_SSO_LOGIN_DETAILS: {
      return {
        ...state,
        ssoLoginDetails: action.payload,
      };
    }

    case types.SCHOOLOGY_APP_COURSES_LIST: {
      return {
        ...state,
        schoologyAppCourseListFlag: action.payload,
      };
    }
    case types.SET_UPDATE_PASSWORD_TOKEN: {
      return {
        ...state,
        passwordTokenDetails: action.payload,
      };
    }

    case types.SAVE_SCHOOLOGY_SSO_LOGIN_DETAILS: {
      return {
        ...state,
        saveSchoologyAppDetails: action.payload,
      };
    }
    case types.CLEAR_SCHOOLOGY_SECTION_ID: {
      return {
        ...state,
        ssoLoginDetails: null,
      };
    }
    case types.IS_SCHOOLOGY_APP_FLAG: {
      return {
        ...state,
        isSchoologyAPP: action.payload,
      };
    }
    default:
      return state;
  }
};

/* istanbul ignore file */
import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {bindActionCreators} from 'redux';
import {userSignIn, setPconfig} from '../redux/actions/Auth';
import {setThemeColor} from '../redux/actions/Setting';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {email_regex, password_regex, white_space, getDefaultDomainDetails, dynamicThemeAllowMenus} from '../util/Extra';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

class SignIn extends Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      loginData: {
        email: '',
        password: '',
      },
      loginDataError: {
        emailError: '',
        passwordError: '',
      },
    };
  }

  componentDidMount() {
    const {signinStatus, history, authUser, userRole} = this.props;
    const countConfigCall = sessionStorage.getItem('countConfigCall');

    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app`);
    }else{
     if(this.countConfigCall === 0){
        this.props.setPconfig({})
        sessionStorage.setItem('countConfigCall', countConfigCall + 1)
      }
    }
  }


  //set current login details in state
  handleChange = e => {
    if (!isEmpty(e.target.value) && !white_space.test(e.target.value) && e.target.name === 'password') {
      return;
    }
    const {loginData} = {
      ...this.state,
    };
    loginData[e.target.name] = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      loginData: {
        ...loginData,
      },
    }));
  };
  //remove extra spaces
  handleChangeOnBlur = e => {
    const {loginData} = {...this.state};
    if (e.target.name === 'email') {
      loginData[e.target.name] = e.target.value.trim();
    }
    this.setState(prevState => ({
      ...prevState,
      loginData: {
        ...loginData,
      },
    }));
  };

  //signin page validation
  validate = () => {
    const {loginData} = this.state;
    let loginDataError = {
      ...this.state.loginDataError,
    };
    const {email, password} = loginData;
    loginDataError = {
      emailError: '',
      passwordError: '',
    };

    if (!email.length > 0) {
      loginDataError.emailError = 'Required';
    }

    if (email.length > 0 && !email_regex.test(email)) {
      loginDataError.emailError = 'Invalid Email';
    }

    if (!password.length > 0) {
      loginDataError.passwordError = 'Required';
    }

    if (password.length > 0 && !password_regex.test(password)) {
      loginDataError.passwordError = 'Invalid Password';
    }

    this.setState({
      loginDataError: {
        ...loginDataError,
      },
    });

    if (loginDataError.emailError || loginDataError.passwordError) {
      return false;
    }
    return true;
  };

  //sent login details to server for login
  submitForm = event => {
    event.preventDefault();
    const {email, password} = this.state.loginData;
    const isValid = this.validate();

    if (isValid) {
      this.props.userSignIn({
        loginDetails: {
          email: email,
          password: password,
        },
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const {signinStatus, history, authUser, userRole, clientConfigration, setPconfig} = nextProps;
    const {serverConfig} = !!clientConfigration && clientConfigration;
    const countConfigCall = sessionStorage.getItem('countConfigCall');


    isEmpty(serverConfig) && history.push('/configration');
    const currentClientFromUrl = sessionStorage.getItem('currentClientFromUrl');
    nextProps.setThemeColor(getDefaultDomainDetails(currentClientFromUrl).themeColor);
    // if already user logged in (browser back navigation). and user login to app
    if (authUser !== null && signinStatus && userRole !== null) {
      history.push(`/app/${userRole}`);
    }
    const {institute_shoppingcart_logout_url} = !!serverConfig && serverConfig;
    // var redirectURL = 'https://lms2.cart.higherl.com/logout';
    // eslint-disable-next-line
    var redirectURL = null;
    if (!!institute_shoppingcart_logout_url) {
      redirectURL = institute_shoppingcart_logout_url;
    }
    if (!!redirectURL) {
      const pathArray = redirectURL.split( '/' );
      const protocol = pathArray[0];
      const host = pathArray[2];
      const url = protocol + '//' + host;
      const localurl = 'http://localhost:3000'
      const localurl_another = 'http://localhost:3001'

      if(window.location.origin !== url && window.location.origin !== localurl && window.location.origin !== localurl_another){
        if(redirectURL.includes('cart') === true){
          setPconfig({})
          window.location.href = redirectURL
        }else{
          if(isEmpty(countConfigCall) || countConfigCall === 0){
            // console.log(countConfigCall)
            setPconfig({})
            sessionStorage.setItem('countConfigCall', countConfigCall + 1);
          }
        }
        
      }
    }
    return null;
    //else window.location.href = 'https://lms2.cart.higherl.com/logout';
  }

  render() {
    const {email, password} = this.state.loginData;
    const {emailError, passwordError} = this.state.loginDataError;
    const {loader, clientConfigration, errorDisplay, errorMessage, pageName} = this.props;
    const {serverConfig} = !!clientConfigration && clientConfigration;
    return (
      <>
        <div className="app-container">
          <div className="app-main-container">
            <main className="login-page">
                <div className="row full-height">
                  <div className="col-md-6 left-section">
                    <div className="login-box-wrap">
                      <div className="logo-wrap">
                        <img className="" alt="icon" src={serverConfig?.institute_logo} />
                      </div>
                      {errorDisplay && pageName === 'SignIn' && (
                      <div className="row form-error-wrap">
                        <span className="default-error">
                          <img src="../img/error-icon.svg" alt="error-Icon"></img>
                          {errorMessage}
                        </span>
                      </div>
                    )}
                      {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'amerikoolersignindesc') && (
                      <h2>LOG IN</h2>
                      )}
                      {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'tdnsignindesc', 'paktolussignindesc') && (
                        <h2>Log in</h2>
                      )}
                      {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'amerikoolersignindesc') && (
                        <h3>Join our growing team of certified installers</h3>
                      )}
                      {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'tdnsignindesc', 'paktolussignindesc') && (
                        <h3>Welcome back! Please enter your Details.</h3>
                      )}
                      <form noValidate autoComplete="off" onSubmit={this.submitForm}>
                        <Grid container>
                          <Grid xs={12} item>
                            <TextField
                              id="userName"
                              label="Username"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              inputProps={{
                                style: {
                                  padding: 13,
                                },
                              }}
                              value={email}
                              onChange={this.handleChange}
                              onBlur={this.handleChangeOnBlur}
                              autoFocus
                              error={emailError ? true : false}
                              disabled={loader}
                              name="email"
                            />
                            {emailError && (
                              <p
                                className="Error"
                                style={{
                                  color: 'red',
                                  fontSize: '14px',
                                  marginTop: '-21px',
                                }}
                              >
                                <ErrorOutlineIcon
                                  style={{
                                    fontSize: '14px',
                                    marginTop: '-4px',
                                    marginRight: '10px',
                                  }}
                                />
                                {emailError}
                              </p>
                            )}
                          </Grid>
                          <Grid xs={12} item>
                            <TextField
                              value={password}
                              inputProps={{
                                maxLength: 16,
                              }}
                              type="password"
                              id="password"
                              label="Password"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              error={passwordError ? true : false}
                              onChange={this.handleChange}
                              disabled={loader}
                              name="password"
                            />
                            {passwordError && (
                              <p
                                className="Error"
                                style={{
                                  color: 'red',
                                  fontSize: '14px',
                                  marginTop: '-21px',
                                }}
                              >
                                <ErrorOutlineIcon
                                  style={{
                                    fontSize: '14px',
                                    marginTop: '-4px',
                                    marginRight: '10px',
                                  }}
                                />
                                {passwordError}
                              </p>
                            )}
                          </Grid>
                          <Button disabled={loader} type="submit" variant="contained" className="default-btn" color="primary" margin="normal" fullWidth>
                            Login
                          </Button>
                        </Grid>
                        {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'amerikoolersignindesc') && (
                        <Link to="/forgot-password" variant="body2" className="forget-link">
                          Forgot your password?
                        </Link>
                        )}
                      </form>
                      {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'paktolussignindesc') && (
                      <Link href="/forgot-password" variant="body2" className="forget-link">
                      Forgot your password?
                    </Link>
                      )}
                       {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'paktolusSignup') && (
                      <p className="signup-link">
                        Do you need an account? <Link href="#">Sign up</Link>
                      </p>
                       )}
                    </div>
                  </div>
                  <div className="col-md-6 right-section">
                    {/* <img className="login-poster-image" alt="icon" src={process.env.PUBLIC_URL + '../../img/login-poster-image.png'} /> */}
                    <div className="login-heading-wrap">
                    {dynamicThemeAllowMenus(getDefaultDomainDetails().instituteName, 'amerikoolersignindesc') && ( 
                      <>
                      <h1>
                        Amerikooler <br />
                        CERTIFIED INSTALLATIONS
                      </h1>
                      <p>
                        Join our growing team of certified Amerikooler installation and service partners and become part of a growing network of skilled
                        providers with access to new business opportunities.
                      </p>
                      </>
                    )}
                    </div>
                  </div>
                </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      userSignIn,setThemeColor,setPconfig
    },
    dispatch,
  );
};

const mapStateToProps = ({auth, error}) => {
  const {loader, authUser, signinStatus, userRole, clientConfigration} = auth;
  const {errorDisplay, errorMessage, pageName} = error;

  return {
    loader,
    authUser,
    signinStatus,
    userRole,
    clientConfigration,
    errorDisplay,
    errorMessage,
    pageName
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
